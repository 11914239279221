import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {HttpService} from '../http/http.service';
import {Appointment} from '../../models/appointment';
import BlueBirdPromise from 'bluebird';

@Injectable()
export class AppointmentService extends HttpService {

  constructor(http: HttpClient) {
    super(http);
  }

  /**
   * Call to validate a SSN number
   *
   * @param ssn
   * @param ssnIso
   */
  validateSsn(ssn: string, ssnIso: string) {
    return new BlueBirdPromise((resolve) => {
      this.get(`${this.apiUrl}/validateSsn`, new HttpParams({fromObject: {ssn, ssnIso}}))
        .subscribe({
          next: () => {
            resolve(true);
          },
          error: () => {
            resolve(false);
          }
        });
    });
  }

  /**
   * Call to create an appointment
   *
   * @param appointment
   * @param doctorId
   */
  newAppointment(appointment: any, doctorId: number) {
    return new BlueBirdPromise((resolve, reject) => {
      this.post(`${this.apiUrl}/doctors/${doctorId}/appointments`, appointment)
        .subscribe({
          next: (appointmentResponse) => {
            resolve(this.stripUselessData(appointmentResponse));
          },
          error: (error) => {
            reject(error);
          }
        });
    });
  }

  /**
   * Call to confirm an appointment with the sms code
   *
   * @param {Appointment} appointment
   * @param body
   */
  confirmAppointment(appointment: Appointment, body: any) {
    return new BlueBirdPromise((resolve, reject) => {
      this.patch(`${this.apiUrl}/doctor/${appointment.doctor_externalId}/appointment/${appointment.externalId}/confirmation`, body)
        .subscribe({
          next: (confirmData) => {
            resolve(this.stripUselessData(confirmData));
          },
          error: (error) => {
            reject(error);
          }
        });
    });
  }

  /**
   * Call to confirm an appointment with the sms code
   *
   * @param {Appointment} appointment
   * @param body
   */
  rescheduleAppointment(appointment: Appointment, body: any) {
    return new BlueBirdPromise((resolve, reject) => {
      this.patch(`${this.apiUrl}/doctor/${appointment.doctor_externalId}/appointment/${appointment.externalId}/reschedule`, body)
        .subscribe({
          next: (rescheduleData) => {
            resolve(this.stripUselessData(rescheduleData));
          },
          error: (error) => {
            reject(error);
          }
        });
    });
  }

  /**
   * Call to get an appointment by eid
   *
   * @param eid
   * @param params
   */
  getAppointment(eid: string, params?: HttpParams) {
    if (typeof params === 'undefined') {
      params = new HttpParams();
    }
    return new BlueBirdPromise((resolve, reject) => {
      this.get(`${this.apiUrl}/doctors/appointments/${eid}`, params)
        .subscribe({
          next: (appointmentResponse) => {
            resolve(this.stripUselessData(appointmentResponse));
          },
          error: (error) => {
            reject(error);
          }
        });
    });
  }
}
