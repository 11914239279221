<div *ngIf="false">
  <input type="hidden" i18n-placeholder="@@__practice_label_center"
         placeholder="{{ '__practice_label_center' | translate}}">
</div>

<app-alert></app-alert>
<app-loader></app-loader>
<div class="group" id="calendar-view" *ngIf="practices.length > 0">

  <div id="patient-doctena-login" *ngIf="showPatientLogin() && (!isAccountRequestComplete && !isLogged)">
    <p> {{ '__i_want_to_book_an_appointment'| translate }}</p>
    <mat-radio-group class="example-radio-group">
      <mat-radio-button value="0" class="booking-with-doctena-radio-button"
                        (click)="isDoctenaUser(true)">
        {{ '__booking_with_doctena_account'| translate }}
      </mat-radio-button>
      <!-- Login form -->
      <app-patient-login *ngIf="showPatientLogin()"
                         (isLogged)="setIsLogged($event)"
                         [countryIso]="countryIso"
                         [consultationGroup]="consultationGroup"
                         [showPatientLoginForm]="showPatientLoginForm">
      </app-patient-login>
      <mat-radio-button value="1" class="booking-without-doctena-radio-button" (click)="isDoctenaUser(false)">
        {{ '__booking_without_doctena_account'| translate }}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div *ngIf="(isAccountRequestComplete || isLogged || !showPatientLogin())">
    <form class="search-box">
      <div class="flex-container">
        <div class="item-flex" *ngIf="specialities.length > 0 && !bingliFeature && !hideSpecialityFilter">
          <mat-form-field class="filter-input">
            <mat-label i18n="Specialities@@__specialities">{{groupFilterLabels.speciality | translate}}</mat-label>
            <input type="text" matInput [formControl]="specialityControl" [matAutocomplete]="speciality"
                   placeholder="{{groupFilterLabels.speciality | translate}}">
            <mat-autocomplete #speciality="matAutocomplete" [displayWith]="displayOptions"
                              (optionSelected)="onSpecialitySelect($event)">
              <mat-option *ngFor="let speciality of specialityOptions | async" [value]="speciality">
                {{speciality.name}}
              </mat-option>
            </mat-autocomplete>
            <div class="arrow-drop-down-btn" *ngIf="!isPreSelect('speciality')">
              <mat-icon>arrow_drop_down</mat-icon>
            </div>
            <div class="reset-btn" *ngIf="isPreSelect('speciality')">
              <button mat-mini-fab color="warn" (click)="onResetSearchFiltersClick('speciality')">
                <mat-icon><b>clear</b></mat-icon>
              </button>
            </div>
          </mat-form-field>
        </div>
        <div class="item-flex" *ngIf="doctors.length > 0 && !bingliFeature && !hidePractitionerFilter">
          <mat-form-field class="filter-input">
            <mat-label i18n="Doctor@@__doctor">{{groupFilterLabels.practitioner | translate}}</mat-label>
            <input type="text" matInput [formControl]="doctorControl" [matAutocomplete]="doctor"
                   placeholder="{{groupFilterLabels.practitioner | translate}}">
            <mat-autocomplete #doctor="matAutocomplete" [displayWith]="displayOptions"
                              (optionSelected)="onDoctorSelect($event)">
              <mat-option *ngFor="let doctor of doctorOptions | async" [value]="doctor">
                {{doctor.name}}
              </mat-option>
            </mat-autocomplete>
            <div class="arrow-drop-down-btn" *ngIf="!isPreSelect('doctor')">
              <mat-icon>arrow_drop_down</mat-icon>
            </div>
            <div class="reset-btn" *ngIf="isPreSelect('doctor')">
              <button mat-mini-fab color="warn" (click)="onResetSearchFiltersClick('doctor')">
                <mat-icon><b>clear</b></mat-icon>
              </button>
            </div>
          </mat-form-field>
        </div>
        <div class="item-flex" *ngIf="practices.length > 0">
          <mat-form-field class="filter-input">
            <mat-label i18n="Practice@@__practice_title">{{groupFilterLabels.practice | translate}}</mat-label>
            <input type="text" matInput [formControl]="practiceControl" [matAutocomplete]="practice"
                   [readOnly]="isPreSelect('practice')"
                   placeholder="{{groupFilterLabels.practice | translate}}">
            <mat-autocomplete #practice="matAutocomplete" [displayWith]="displayOptions"
                              (optionSelected)="onPracticeSelect($event)">
              <mat-option
                *ngFor="let practice of practiceOptions | async | sort: practiceSortOrder:practiceOrderProperty"
                [value]="practice">
                {{practice.name}}
              </mat-option>
            </mat-autocomplete>
            <div class="arrow-drop-down-btn" *ngIf="!isPreSelect('practice')">
              <mat-icon>arrow_drop_down</mat-icon>
            </div>
            <div class="reset-btn" *ngIf="isPreSelect('practice')">
              <button mat-mini-fab color="warn" (click)="onResetSearchFiltersClick('practice')">
                <mat-icon><b>clear</b></mat-icon>
              </button>
            </div>
          </mat-form-field>
        </div>
      </div>
    </form>

    <app-filters
      *ngIf="isDisplayable()"
      (isBookingRulesSelected)="setStatusBookingRulesSelected($event)"
      (reasonOfVisitWithTriageKey)="setReasonOfVisitDoctenaWithTriageKey($event)"
      (isBingliRovSelected)="setIsBingliRovSelected($event)"
      (isRovSelected)="loadAgendasPage()"
      [showBingliForm]="!completeBingliSurvey"
      [preselectRov]="preselectBingliRov"
      [groupEid]="groupEid"
      [isGroupView]="isGroupView"
      [isDisabled]="isBookingRulesSelected && bingliFeature"
      [filterParams]="getFilterParams()">
    </app-filters>

    <!-- BINGLI PART --->
    <div *ngIf="showBingliForm && bingliFeature && reasonOfVisitDoctenaWithTriageKey.length && isBingliRovSelected">
      <!-- Bingli Form  -->
      <app-bingli-form (onSurveyComplete)="isCompleteSurvey($event)"
                       (reasonOfVisitBingli)="extractReasonOfVisitBingli($event)"
                       [features]="groupFeatures"
                       [entityName]="'group'"
                       [entityEid]="groupEid"
                       [featureCode]="'triage'"
                       [preselectBingliRov]="reasonOfVisitDoctenaWithTriageKey"
                       [consentSignBy]="practiceName"
                       [consentSignContact] = "practicePhone">
      </app-bingli-form>
    </div>
    <div class="sticky-top" *ngIf="isDisplayable() && completeBingliSurvey">
      <a
        *ngIf="isInitiatedByDoctorView()"
        class="back-button link"
        (click)="goBackToDoctorView()"
        i18n="@@__back_doctor_profile">
        <mat-icon>arrow_back</mat-icon>
        <span>{{'__back_doctor_profile' | translate}}</span>
      </a>
      <app-week-selector [ngClass]="{'hide-picture': hidePicture}"></app-week-selector>
    </div>

    <app-list
      *ngIf="isDisplayable() && completeBingliSurvey"
      [agendas]="agendas"
      [practiceAgendas]="groupAgendas"
      [specialitiesAliases]="specialitiesAliases">
    </app-list>

    <mat-paginator [length]="agendasFiltered().length ?? 0" [pageSize]="pageSize" (page)="loadAgendasPage($event)" [hidePageSize]="true"
                   [showFirstLastButtons]="true" class="mat-paginator-sticky"
                   [ngClass]="{'paginator-top-border': agendasFiltered() && agendasFiltered().length > 0}">
    </mat-paginator>
  </div>
</div>

<div *ngIf="isDisplayable()" class="powered-by">
  <p *ngIf="!hidePowerBy">
    {{ '__powered_by' | translate}}
    <a rel="nofollow" [href]="profileUrl" target="_blank">
      Doctena
    </a>
    -
  </p>
  <a i18n="Privacy policy@@__privacy_settings" [href]="policyUrl" target="_blank" rel="nofollow">
    {{ '__privacy_settings' | translate}}
  </a>
</div>

<app-cookie-info *ngIf="isDisplayable() && !hideCookieBanner()" [countryIso]="countryIso"></app-cookie-info>
