<div *ngIf="false" xmlns="http://www.w3.org/1999/html">
  <input type="hidden" i18n-placeholder="@@__custom_notification_has_read_checkbox_label"
         placeholder="{{ '__custom_notification_has_read_checkbox_label' | translate}}">
  <input type="hidden" i18n-placeholder="@@__teleconsultation_booking_warning"
         placeholder="{{ '__teleconsultation_booking_warning' | translate}}">
  <input type="hidden" i18n-placeholder="@@__booking_patient_info_step1"
         placeholder="{{ '__booking_patient_info_step1' | translate}}">
</div>

<form [formGroup]="formGroup">
  <!-- Doctor's remark (GO Doctor) -->
  <div class="col-12" *ngIf="getDoctorRemark()">
    <p class="no-mg-bottom" i18n="@@__remark_from_practitioner">
      <strong>{{ '__remark_from_practitioner' | translate }}</strong>
    </p>
    <p>{{ getDoctorRemark() }}</p>
  </div>

  <!-- Doctor custom message -->
  <div class="alert alert-info" *ngIf="calendarService?.customMessage">
    <p class="semi-mg-bottom"><strong i18n="@@__custom_notification_title">
      {{ '__custom_notification_title' | translate | sprintf: calendarService?.customMessage?.practitionerName }}
    </strong></p>

    <p class="no-mg-bottom newlines">{{ calendarService?.customMessage?.message }}</p>

    <!-- Optional checkbox for agreement with custom message -->
    <div class="formElement formElement--alert semi-mg-top"
         *ngIf="calendarService?.customMessage && calendarService?.customMessage?.hasReadCheckbox">
      <mat-checkbox formControlName="customMessageAgreement" required>
        {{ '__custom_notification_has_read_checkbox_label' | translate }}
      </mat-checkbox>
      <mat-error *ngIf="isPristine && formGroup?.controls['customMessageAgreement'].hasError('required')">
        {{ '__legend_field_required' | translate }}
      </mat-error>
    </div>
  </div>

  <!-- Video consultation warning -->
  <div class="alert alert-success"
       *ngIf="calendarService?.selectedAgenda?.teleconsultation && selectedReason?.teleconsultation">
    <div class="formElement formElement--alert semi-mg-top">
      <mat-checkbox formControlName="teleConsultationValidation">
        {{ '__teleconsultation_booking_warning' | translate }}
      </mat-checkbox>
      <mat-error
        *ngIf="isPristine && formGroup?.controls['teleConsultationValidation']?.hasError('required')">
        {{ '__legend_field_required' | translate }}
      </mat-error>
      <a [href]="teleconsultationInfoUrl" target="_blank" i18n="@@__learn_more">
        {{ '__learn_more' | translate }}
      </a>
    </div>
  </div>

  <!-- Radio field : Is Doctena user ? -->
  <div class="formElement" *ngIf="showLoginPatient()">
    <label class="col-12 control-label" for="isDoctenaUser" i18n="@@form_appointment_doctena_user">
      {{ 'form_appointment_doctena_user' | translate }}
    </label>
    <mat-radio-group class="col-12" id="isDoctenaUser" formControlName="isDoctenaUser">
      <mat-radio-button value="" [checked]="true"
                        i18n="@@__label_no">{{ '__label_no' | translate }}
      </mat-radio-button>
      <mat-radio-button value="true" i18n="@@__label_yes">{{ '__label_yes' | translate }}</mat-radio-button>
    </mat-radio-group>
    <br/><br/>

    <!-- Login form -->
    <app-patient-login
      (isLogged)="setIsLogged($event)"
      [countryIso]="countryIso"
      [consultationGroup]="formGroup"
      [showPatientLoginForm]="formGroup.value['isDoctenaUser'] && !patientService?.patient">
    </app-patient-login>
  </div>

  <!-- Returning patient radio input -->
  <div [hidden]="!showPatientReturningInput || hidePatientRelation" class="formElement">
    <label class="col-12 control-label" for="returningPatient"
           i18n="@@__booking_form_appointment_returning_patient">{{ '__booking_form_appointment_returning_patient' | translate }}</label>
    <mat-radio-group class="col-12" id="returningPatient" formControlName="returningPatient">
      <mat-radio-button
        [checked]="formGroup.value['returningPatient'] === 'false'"
        value="false"
        i18n="@@__label_new_patient">
        {{ '__label_new_patient' | translate }}
      </mat-radio-button>
      <mat-radio-button
        [checked]="formGroup.value['returningPatient'] === 'true'"
        value="true"
        i18n="@@__label_returning_patient">
        {{ '__label_returning_patient' | translate }}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <!-- Reason of visit -->
  <div class="formElement">
    <label class="col-12 control-label" for="reason"
           i18n="@@__form_appointment_reason"> {{ '__form_appointment_reason' | translate }}</label>
    <mat-form-field id="reason" class="col-md-12 col-lg-8 col-xl-7 d-block">
      <input matInput [value]="selectedReason?.name" disabled>
    </mat-form-field>

    <mat-form-field class="col-md-12 col-lg-8 col-xl-7 d-block patient-note" *ngIf="patientNoteEnabled">
      <textarea class="patient-note-textarea" matInput #message maxlength="500"
                formControlName="patientNote" i18n-placeholder="@@__form_appointment_reason_note_placeholder"
                placeholder="{{ '__form_appointment_reason_note_placeholder' | translate}}">
      </textarea>
      <mat-hint align="end">{{ message?.value?.length }} / 500</mat-hint>
    </mat-form-field>
  </div>

  <!-- CUSTOM FIELDS FOR APPOINTMENT -->
  <app-fieldset
    *ngIf="customFields?.appointment"
    [fields]="customFields?.appointment?.fields"
    [parentForm]="formGroup"
    (keyDownEvent)="bookingService.monitorKeyInput($event)"
    [isPristine]="isPristine">
  </app-fieldset>

  <div class="col-12 action-buttons">
    <button (click)="onClickBack()" class="btn-secondary float-left" mat-button matStepperPrevious
            i18n="@@__label_back">{{ '__label_back' | translate }}
    </button>
    <button class="btn-success float-right" mat-button matStepperNext (click)="onClickNext()"
            i18n="@@__label_next"> {{ '__label_next' | translate }}
    </button>
  </div>
</form>
