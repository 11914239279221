import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HelpersService} from '../service/helpers/helpers.service';
import {DoctorService} from '../service/doctor/doctor.service';
import {AgendaService} from '../service/agenda/agenda.service';
import { HttpParams } from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {Doctor} from '../models/doctor';
import {Practice} from '../models/practice';
import {Agenda} from '../models/agenda';
import {CalendarService} from '../service/calendar/calendar.service';
import {TrackingService} from '../service/tracking/tracking.service';
import {ReviewsService} from '../service/reviews/reviews.service';
import {environment} from '../../environments/environment';
import {Alert} from '../models/alert';
import {AuthenticationService} from '../service/authentication/authentication.service';
import {AlertService} from '../service/alert/alert.service';
import {gatewayName, homeLoginDoctenaAccount, hideCookieBanner, hidePowerBy} from '../routing/routing.component';
import {UtilitiesService} from '../service/utilities/utilities.service';
import {FormBuilder, UntypedFormGroup} from '@angular/forms';
import {BingliTrait} from '../trait/bingli.trait';

declare let $: any;

@Component({
  selector: 'app-doctor',
  templateUrl: './doctor.component.html',
  styleUrls: ['./doctor.component.scss']
})
export class DoctorComponent extends BingliTrait implements OnInit {
  id: string = this.route.snapshot.paramMap.get('id');
  doctor: Doctor;
  practice: Practice;
  agenda: Agenda;
  isPracticeView = false;
  hidePicture = this.route.snapshot.paramMap.get('showPicture') === '0';
  profileUrl: string;
  countryIso: string;
  policyUrl: string;
  hidePowerBy = hidePowerBy;
  specialitiesAliases: Array<any> = [];
  // Form group use by booking keep the same name to share form control
  consultationGroup: UntypedFormGroup;
  showPatientLoginForm: boolean;
  isLogged = false;
  bingliFeature = false;
  showBingliForm = false;
  isBookingRulesSelected = false;
  agendaFeatures = [];
  isAccountRequestComplete = false;

  constructor(private _formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private helpers: HelpersService,
              private doctorService: DoctorService,
              private agendaService: AgendaService,
              public calendarService: CalendarService,
              public trackingService: TrackingService,
              public reviewsService: ReviewsService,
              public authenticationService: AuthenticationService,
              public alertService: AlertService,
              public utilitiesService: UtilitiesService,
              public translateService: TranslateService) {
    super();
    this.profileUrl = 'https://www.doctena.com';
  }

  ngOnInit() {
    if (!this.helpers.isGUID(this.id)) {
      console.error('The URL is not valid. The practice\'s id should be a GUID.');
      // Go to "Page not found"
      this.router.navigate([''], {relativeTo: this.route, skipLocationChange: true});
      return;
    }

    this.consultationGroup = this._formBuilder.group({isDoctenaUser: false});
    // Get bearer for the app
    this.authenticationService.authApp().subscribe(result => {
      if (result !== true) {
        // Login failed
        const alert = new Alert();
        alert.type = 'error';
        alert.text = '__title_error_occurred';
        this.alertService.setAlert(alert);
        return;
      }

      if (gatewayName) {
        this.utilitiesService.getGatewaySpecialitiesAliasName(gatewayName,
          response => this.getGatewaySpecialitiesAliasNameCallback(response), error => this.getGatewaySpecialitiesAliasNameError());
        return;
      }

      // Load agenda data
      this.loadAgenda();
    });
  }

  /**
   * Load agenda data
   *
   * @private
   */
  private loadAgenda() {
    // Get agenda
    let params = new HttpParams(); // Add params of select rules if we have it
    params = params.append('language', this.translateService.currentLang);

    // Load agenda data
    return this.agendaService.getAgenda(this.id, params)
      .then((agenda: Agenda) => this.getAgendaCallback(agenda));
  }

  /**
   * utilisesService - getGatewaySpecialitiesAliasName callback
   *
   * @param specialitiesAliases
   * @private
   */
  private getGatewaySpecialitiesAliasNameCallback(specialitiesAliases: Array<any>) {
    if (specialitiesAliases && specialitiesAliases.length > 0) {
      this.specialitiesAliases = specialitiesAliases;
    }

    this.loadAgenda();
  }

  /**
   * utilisesService - getGatewaySpecialitiesAliasName error callback
   *
   * @private
   */
  private getGatewaySpecialitiesAliasNameError() {
    const alert = new Alert();
    alert.type = 'warning';
    alert.text = '__title_error_occurred';
    this.alertService.setAlert(alert);

    this.loadAgenda();
  }

  private getAgendaCallback(response) {
    if (response.id || typeof response.id !== 'undefined') {
      const languageCode = this.translateService.currentLang;
      response.doctor.mainSpeciality = response.mainSpeciality;
      this.agenda = response;
      this.doctor = response.doctor;
      this.practice = response.practice;
      this.countryIso = this.practice.country_iso;
      this.calendarService.addAgenda(this.agenda);
      this.profileUrl = this.calendarService.urlProfile('doctor');
      this.trackingService.selectedAgenda = this.agenda;
      this.trackingService.eventRequestWidgetLoaded('doctor', this.doctor);
      this.policyUrl = environment.policy[languageCode];

      // Get agenda
      let params = new HttpParams(); // Add params of select rules if we have it
      params = params.append('language', this.translateService.currentLang);

      // Load Feature for an agenda
      this.agendaService.getAgendaWithFeatures(this.id, params)
        .then((result: any) => this.getAgendaFeaturesCallback(result))
        .catch((error: any) => console.error(error));

      // Load reviews data with doctor eid
      this.reviewsService.getReviews([response.doctor.externalId])
        .then((result: any) => this.getReviewsCallback(result))
        .catch((error: any) => console.error(error));
    }
  }

  /**
   * Get agenda with features callback
   *
   * @param response
   * @private
   */
  private getAgendaFeaturesCallback(response) {
    // Filter by features
    if (response.features) {
      this.agendaFeatures = response.features;
      this.calendarService.availableFeatures = response.features;
      // Feature Bingli
      if (this.agendaFeatures.hasOwnProperty('triage') && this.agendaFeatures['triage'].hasOwnProperty('client_ref')) {
        // If one of agenda using bingli feature, we'll display the bingli survey
        if (!this.bingliFeature) {
          // hide agenda in the view !
          this.bingliFeature = true;
          this.completeBingliSurvey = false;
        }
      } else {
        // In case of wrong setup (ROV and self service) -  no bingli feature, but the rov have triageKey
        // create a central check for both
        this.completeBingliSurvey = true;
      }
    }
  }

  /**
   * reviewsService - getReviews callback
   * @param response
   * @private
   */
  private getReviewsCallback(response) {
    this.reviewsService.storeReviews(response);
  }

  /** Manage the patient login button */
  isDoctenaUser(status) {
    if (status) {
      this.showPatientLoginForm = true;
      return this.consultationGroup.patchValue({isDoctenaUser: true});
    }
    this.showPatientLoginForm = false;
    this.isAccountRequestComplete = true;
    return this.consultationGroup.patchValue({isDoctenaUser: false});
  }

  setIsLogged(isLogged: boolean) {
    this.isLogged = isLogged;
    this.showPatientLoginForm = !this.showPatientLoginForm;
  }

  // Manage the patient login button
  showPatientLoginButton() {
    return !(this.bingliFeature && this.isBookingRulesSelected) && homeLoginDoctenaAccount;
  }

  /**
   * Check if booking rules are all selected
   * @param status
   * @private
   */
  setStatusBookingRulesSelected(status: boolean) {
    this.isBookingRulesSelected = status;
    if (status) {
      this.showBingliForm = true;
    }
  }

  /**
   * Check if the banner should be displayed
   */
  isDisplayable() {
    return this.agenda;
  }

  isHideCookieBanner() {
    return hideCookieBanner;
  }
}
