<div *ngIf="false">
  <input type="hidden" i18n-placeholder="@@__calendar_slot_more"
         placeholder="{{ '__calendar_slot_more' | translate}}">
  <input type="hidden" i18n-placeholder="@@__calendar_slot_less"
         placeholder="{{ '__calendar_slot_less' | translate}}">
  <input type="hidden" i18n-placeholder="@@__walkin_legend"
         placeholder="{{ '__walkin_legend' | translate}}">
  <input type="hidden" i18n-placeholder="@@__timeframe_legend"
         placeholder="{{ '__timeframe_legend' | translate}}">
  <input type="hidden" i18n-placeholder="@@__at_home"
         placeholder="{{ '__at_home' | translate}}">
</div>

<div class="col-12 calendarContainer">
  <div class="content-calendar-container wrapper-slots" [ngClass]="{'practice-view': isPracticeView}"
    #containerCalendar>
    <div class="slot-column"
      [ngStyle]="{'background-color' : (i%2 == 1) ? extendedColorDay : '#'}"
      *ngFor="let day of calendarService.weekDays; let i = index"
      [hidden]="displayNoAvailabilitiesMessage() || displayNextAvailabilitiesMessage()">
      <div class="slot-day"
        [hidden]="(i >= calendarService.nbDays)"
        *ngIf="getSlotsOfDay(agenda, day).length > 0">
        <div class="availabilitiesContainer"
          *ngFor="let slot of returnRightAmountOfSlots(day); let i = index">

          <a rel="nofollow"
             mat-raised-button
             matTooltip="{{ getTooltipForSlot(slot) | translate }}"
             matTooltipDisabled="{{!isLongSlot(slot.status) || isSmallScreen}}"
            (click)="handleClickOnSlot(slot)"
            [ngClass]="{
              'btn-success':slot.status !== slotWalkinStatus,
              'slot-walkin':slot.status === slotWalkinStatus,
              'slot-timeframe':slot.status === slotTimeframeStatus,
              'slot-home-visit':slot.status === slotHomeVisitStatus,
              'Slot--long': isLongSlot(slot.status)
            }"
            [ngStyle]="{
              'background-color' : extendedColorBtn,
              'border' : 0
            }">

            <div *ngIf="isLongSlot(slot.status) && isSmallScreen" class="Slot__legend">
              {{ getTooltipForSlot(slot) | translate }}
            </div>

            <div class="slot-start">
              {{ slot.start | moment : 'T'}}
            </div>

            <div *ngIf="isLongSlot(slot.status)" class="slot-dash-walkin">
              -
            </div>

            <div class="slot-end" *ngIf="isLongSlot(slot.status)">
              {{slot.end | moment : 'T'}}
            </div>

          </a>
        </div>
        <span class="see-more-link" (click)="toggleIsExpanded()"
        *ngIf="returnTotalSpaceOccupiedBySlots(day) > 5">
          {{isExpanded ? ('__calendar_slot_less' | translate) : ('__calendar_slot_more' | translate)}}
        </span>
      </div>
    </div>
  </div>

  <!--Display no availabilities-->
  <div *ngIf="displayNoAvailabilitiesMessage()" id="noAvailabilitiesMessage" class="no-availabilities-parent">
    <div class="no-availabilities-message">
    <label
      *ngIf="isUnavailabilityReal()"
      class="no-availabilities"
      i18n="@@__calendar_no_availabilities">
      {{'__calendar_no_availabilities' | translate}}
    </label>
    <p class="no-filters-selected" *ngIf="displayNoRulesSelected()"
           i18n="@@__no_booking_rules_selected_alert">
      {{'__no_booking_rules_selected_alert' | translate}}
    </p>
    <p class="no-filters-selected" *ngIf="displayNoRovSelected()"
           i18n="@@__no_rov_selected_alert">
      {{'__no_rov_selected_alert' | translate}}
    </p>
    <br>
    <button mat-raised-button
      i18n="@@__action_call_to_book"
      *ngIf="(agenda.practice.phone !== null && isUnavailabilityReal())"
      (click)="callToBookBtn()"
      [hidden]="hasCallToBookClick"
      class="btn-success btnCallToBook"
      [ngStyle]="{
        'background-color' : extendedColorBtn,
        'border' : 0
      }">
      {{'__action_call_to_book' | translate}}
    </button>
    <a i18n="@@__msg_call_to_book" [hidden]="!hasCallToBookClick" [href]="'tel:' + agenda.practice.phone" class="callPhone hide"
       *ngIf="(agenda.practice.phone !== null)">
      {{'__msg_call_to_book' | translate}} {{agenda.practice.phone}}
    </a>
    </div>
  </div>

  <!--Display next availability link-->
  <div *ngIf="displayNextAvailabilitiesMessage()" id="nextAvailabilityMessage" class="next-availability-parent">
    <div class="next-availability-message">
    <label i18n="@@__calendar_next_availability" class="next-availabilities">
      {{'__calendar_next_availability' | translate}}
    </label>
    <button class="btn btn-link" style="padding-top: 2px !important;" (click)="goToNextAvailabilities(nextSlot$.value)">
      {{(nextSlot$ | async)?.toFormat('dd-LL-yyyy')}}
    </button>
    </div>
  </div>
</div>
