import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DoctorComponent} from './doctor/doctor.component';
import {RoutingComponent} from './routing/routing.component';
import {PracticeComponent} from './practice/practice.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {RescheduleComponent} from './reschedule/reschedule.component';
import {GroupComponent} from './group/group.component';
import {BookingStepsComponent} from './booking-steps/booking-steps.component';

const childrenRoutes = [
  {
    path: 'practice/:id',
    component: PracticeComponent
  },
  {
    path: 'doctor/:id',
    component: DoctorComponent
  },
  {
    path: 'group/:id',
    component: GroupComponent
  },
  {
    path: 'reschedule/:id',
    component: RescheduleComponent
  },
  {
    path: 'doctor/:id/:showPicture',
    component: DoctorComponent
  },
  {
    path: 'practice/:id/:showPicture',
    component: PracticeComponent
  },
  {
    path: 'group/:id/:showPicture',
    component: GroupComponent
  },
  {
    path: 'booking-steps',
    component: BookingStepsComponent
  }
];

export const routes: Routes = [
  {
    path: ':language',
    component: RoutingComponent,
    children: childrenRoutes
  },
  {
    path: '',
    component: RoutingComponent,
    children: childrenRoutes
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
