import {ChangeDetectorRef, Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Agenda} from '../models/agenda';
import {Doctor} from '../models/doctor';
import {Slot} from '../models/slot';
import {CalendarService} from '../service/calendar/calendar.service';
import {ActivatedRoute, Router} from '@angular/router';
import {extendedColorBtn, extendedColorDay} from '../routing/routing.component';
import {BookingModalComponent} from '../booking-modal/booking-modal.component';
import {filter, isEqual, isEqualWith, isMatch} from 'lodash';
import {equals, TranslateModule, TranslateService} from '@ngx-translate/core';
import {AsyncPipe, NgClass, NgForOf, NgIf, NgStyle} from '@angular/common';
import {MatAnchor, MatButton} from '@angular/material/button';
import {MatTooltip} from '@angular/material/tooltip';
import {MomentPipe} from '../pipe/moment/moment.pipe';
import {DateTime} from 'luxon';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';


@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  imports: [
    TranslateModule,
    NgClass,
    NgIf,
    NgStyle,
    NgForOf,
    MatAnchor,
    MatTooltip,
    MatButton,
    MomentPipe,
    AsyncPipe
  ],
  standalone: true
})
export class CalendarComponent implements OnInit {
  hasNoSelected = false;
  slotWalkinStatus = Slot.StatusEnum.Walkin;
  slotAvailableStatus = Slot.StatusEnum.Available;
  slotTimeframeStatus = Slot.StatusEnum.Timeframe;
  slotHomeVisitStatus = Slot.StatusEnum.HomeVisit;
  hasCallToBookClick = false;
  extendedColorBtn: string;
  extendedColorDay: string;
  isExpanded = false; // Has been unfolded with the "see more" button
  isSmallScreen = window.innerWidth < 768;
  bookingParams: any;

  // To create event resize end
  resizeTimeout: any;
  firstResizeEvent = null;

  nextSlot$ = new BehaviorSubject<Slot | null>(null);


  @Input() agenda: Agenda;
  @Input() isPracticeView = false;
  @ViewChild('containerCalendar', {static: false}) containerCalendar: any;

  constructor(private route: ActivatedRoute,
              private router: Router,
              public calendarService: CalendarService,
              public translateService: TranslateService,
              private changeDetectorRef: ChangeDetectorRef,
              public dialog: MatDialog) {
    // Init services
    this.calendarService.languageIso = this.translateService.currentLang;
    this.bookingParams = [];

    // Listen to changes in the slots
    this.calendarService.slots$.subscribe((newSlots: Array<any>) => {
      this.nextSlot$.next(this.calendarService.getNextSlotByAgenda(this.agenda));
      this.changeDetectorRef.detectChanges();
    });

    // Listen to changes in the booking params
    this.calendarService.bookingParams$.subscribe((bookingParams) => {
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnInit() {
    this.extendedColorBtn = extendedColorBtn;
    this.extendedColorDay = extendedColorDay;
  }


  // Listen to window resize
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {

    // First Resize Event is set at the beginning of resize to allow
    // comparisons with the end of the resize
    if (!this.firstResizeEvent) {
      this.firstResizeEvent = {
        nbDays: this.calendarService.nbDays
      };
    }

    // The time logic depends on the size of the screen
    this.calendarService.setCalendarFormat(event.target.innerWidth);
    this.isSmallScreen = window.innerWidth < 768;

    // Used to calculate end of resize
    const self = this;
    clearTimeout(this.resizeTimeout);

    function onResizeEnd() {
      if (self.calendarService.nbDays !== self.firstResizeEvent.nbDays) {
        self.calendarService.getAvailabilities();
      }
      self.firstResizeEvent = null;
    }

    this.resizeTimeout = setTimeout(onResizeEnd, 300); // Change timeout value to wait longer before calling onResizeEnd
  }

  isSlotInDay(slot: Slot, day: DateTime) {
    return (slot.start as DateTime).hasSame(day, 'day');
  }

  getSlotsOfDay(agenda: Agenda, day: DateTime): Array<Slot> {
    const slots = this.calendarService.getSlotsByAgenda(agenda);
    return filter(slots, (slot) => {
      return this.isSlotInDay(slot, day);
    });
  }

  /**
   * This function will limit the number of slots to show in the template
   * We need to show only 5 slots in a contracted view (not expanded)
   * Also walkin slots (slots with a start and an end) counts for two slots
   *
   * @param day
   */

  returnRightAmountOfSlots(day) {
    let slots = this.getSlotsOfDay(this.agenda, day);

    if (!this.isExpanded) {
      let count = 0, slotValue = 0, i;
      for (i = 0; count < 5 && i < slots.length; i++) {
        // End the iteration sooner if we have slot with a walkinStatus.
        // They are worth two normal slots
        slotValue = this.isLongSlot(slots[i].status) ? 2 : 1;
        count = count + slotValue;
      }

      // If count has exceeded maximum, we exclude the last slot
      if (count > 5) {
        i--;
      }

      slots = slots.slice(0, i);
    }

    return slots;
  }

  /**
   * Return a number that represents the space taken by the slots of one day
   * 1 is the space value of a normal slot.
   *
   * @param day
   */
  returnTotalSpaceOccupiedBySlots(day) {

    // Get slots of the day
    const slots = this.getSlotsOfDay(this.agenda, day);

    // Make total of slots in the array
    return slots.reduce((accumulator, slot) => {

      // Count 1 if normal slot, but 2 if it's a long slot
      return accumulator + (this.isLongSlot(slot.status) ? 2 : 1);
    }, 0);
  }

  /**
   * Get term to translate for the tooltip of the slot if we display it
   *
   * @param slot
   * @returns {string}
   */
  getTooltipForSlot(slot) {
    switch (slot.status) {
      case this.slotWalkinStatus:
        return '__walkin_legend';
      case this.slotTimeframeStatus:
        return '__timeframe_legend';
      case this.slotHomeVisitStatus:
        return '__at_home';
    }
  }


  /**
   * Method to know if we display a message for no availabilities
   */
  displayNoAvailabilitiesMessage() {
    return !this.calendarService.getNextSlotByAgenda(this.agenda) &&
      ((this.calendarService.getSlotsByAgenda(this.agenda).length === 0) || !this.calendarService.bookingParams['reasonOfVisit']);
  }

  /**
   * Method to know if we display a message for next availability
   */
  displayNextAvailabilitiesMessage() {
    return this.nextSlot$.value &&
      ((this.calendarService.getSlotsByAgenda(this.agenda).length === 0) &&
        this.calendarService.bookingParams['reasonOfVisit']);
  }

  /**
   * Check if we need to display the message to select rules
   *
   * @returns {boolean}
   */
  displayNoRulesSelected() {
    return this.calendarService.hasBookingRules &&
      !this.calendarService.hasReasonsOfVisits &&
      (this.calendarService.bookingParams['bookingRules']?.length === 0);
  }

  /**
   * Check if we need to display the message to select rov
   *
   * @returns {boolean}
   */
  displayNoRovSelected() {
    return this.calendarService.hasReasonsOfVisits && this.calendarService.bookingParams['reasonOfVisit']?.length === 0;
  }

  /**
   * Return true if the missing availabilities aren't due to the unfilled filters forms
   * In other words, return true if the user correctly filled the forms and there is still
   * no new availabilities
   *
   */
  isUnavailabilityReal() {
    return !this.displayNoRulesSelected() && !this.displayNoRovSelected() && this.calendarService.getSlotsByAgenda(this.agenda);
  }

  isLongSlot(status) {
    return (
      status === this.slotWalkinStatus ||
      status === this.slotHomeVisitStatus ||
      status === this.slotTimeframeStatus
    );
  }

  /**
   * Method to display the phone number of the practice if the user click on the button
   */
  callToBookBtn() {
    this.hasCallToBookClick = true;
  }

  /**
   * Actions on click event on slot
   *
   * @param slot  Slot  slot that was clicked
   */
  handleClickOnSlot(slot: Slot) {

    const selectedReasons = this.calendarService.bookingParams['reasonOfVisit'],
      selectedReason = selectedReasons.find((r) => r.agendaEid === this.agenda.externalId);

    switch (true) {
      case (selectedReason.onlinePayment): // Show a modal if the selected ROV is for online payment
        this.dialog.open(BookingModalComponent, {
          maxWidth: '100%',
          minWidth: '50%',
          position: {
            top: '50px'
          },
          data: {
            backButton: true,
            text: '__notification_online_payment',
            continue: () => window.open(selectedReason.profileUrl, '_blank')
          }
        });
        break;
      case (this.agenda.doctor.otherDoctor): // Show modal if doctor has the option "otherDoctor" activated
        this.dialog.open(BookingModalComponent, {
          maxWidth: '100%',
          minWidth: '50%',
          position: {
            top: '50px'
          },
          data: {
            backButton: true,
            text: '__notification_other_doctor',
            continue: () => this.startBookingProcess(slot)
          }
        });
        break;
      default: // Default process
        this.startBookingProcess(slot);
        break;
    }
  }

  /**
   * Keep the informations about appointment in the calendarService
   * and redirect to the booking form
   */
  startBookingProcess(slot: Slot) {
    if (slot.status === this.slotWalkinStatus) {
      return;
    }

    if ((slot.status === Slot.StatusEnum.DoxterTerminland) || (this.agenda.doctor.terminlandGateway === true)
      || (this.agenda.doctor.portalVersion === Doctor.PortalVersionEnum.Sanmax && slot.bookingUrl !== null)) {
      window.open(slot.bookingUrl, '_self');
      return;
    }

    this.calendarService.selectedSlot = slot;
    this.calendarService.selectedAgenda = this.agenda;
    const selectedReasons = this.calendarService.bookingParams['reasonOfVisit'];
    const selectedReason = selectedReasons.find((r) => r.agendaEid === this.agenda.externalId);
    const locale = this.translateService.currentLang;

    this.calendarService.getCustomFields(this.agenda.externalId, slot.status, (selectedReason || {}).externalId, locale, (response) => {
      if (selectedReason) {
        this.calendarService.getCustomMessage(this.agenda.externalId, selectedReason.externalId, locale, () => {
          this.router.navigate([locale, 'booking-steps']);
        });
      } else {
        this.router.navigate([locale, 'booking-steps']);
      }
    });

  }

  /**
   * Method to go to the next availability
   */
  goToNextAvailabilities(date) {
    this.calendarService.changeDate(date);
  }

  toggleIsExpanded() {
    this.isExpanded = !this.isExpanded;
  }
}
