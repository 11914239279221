import {Component, OnInit} from '@angular/core';
import BlueBirdPromise from 'bluebird';
import {ActivatedRoute, Router} from '@angular/router';
import {HelpersService} from '../service/helpers/helpers.service';
import {Agenda} from '../models/agenda';
import {Alert} from '../models/alert';
import {HttpParams} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {TrackingService} from '../service/tracking/tracking.service';
import {ReviewsService} from '../service/reviews/reviews.service';
import {AuthenticationService} from '../service/authentication/authentication.service';
import {AlertService} from '../service/alert/alert.service';
import {AgendaService} from '../service/agenda/agenda.service';
import {GroupService} from '../service/group/group.service';
import {
  gatewayName,
  hidePowerBy,
  ignoreDoctors,
  preSelectedSpeciality,
  preSelectedDoctor,
  preSelectedPractice,
  homeLoginDoctenaAccount,
  hideCookieBanner,
  hideSpecialityFilter,
  hidePractitionerFilter, practiceOrdering, customPracticeLabel
} from '../routing/routing.component';
import {environment} from '../../environments/environment';
import {CalendarService} from '../service/calendar/calendar.service';
import {Speciality} from '../models/speciality';
import {Doctor} from '../models/doctor';
import {Practice} from '../models/practice';
import {FormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Observable} from 'rxjs/internal/Observable';
import {map, startWith} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {UtilitiesService} from '../service/utilities/utilities.service';
import {BingliTrait} from '../trait/bingli.trait';
import {lowerCase} from 'lodash';
import {PageEvent} from '@angular/material/paginator';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent extends BingliTrait implements OnInit {

  groupEid: string = this.route.snapshot.paramMap.get('id');
  agendas: Array<Agenda>;
  isGroupView = true;
  groupAgendas: Array<any>;
  groupAgendasFeatures: Array<any>;
  specialitiesAliases: Array<any>;
  profileUrl: string;
  countryIso: string;
  policyUrl: string;
  specialities: Speciality[] = [];
  doctors: Doctor[] = [];
  practices: Practice[] = [];
  selectedSpeciality: Speciality;
  selectedDoctor: Doctor;
  selectedPractice: Practice;
  hidePowerBy = hidePowerBy;
  preSelectedSpeciality = preSelectedSpeciality;
  preSelectedDoctor = preSelectedDoctor;
  preSelectedPractice = preSelectedPractice;
  hideSpecialityFilter = hideSpecialityFilter;
  hidePractitionerFilter = hidePractitionerFilter;
  practiceSortOrder = '';
  practiceOrderProperty: string[] = [];

  // Search form
  practiceControl = new UntypedFormControl();
  practiceOptions: Observable<Practice[]>;
  doctorControl = new UntypedFormControl();
  doctorOptions: Observable<Doctor[]>;
  specialityControl = new UntypedFormControl();
  specialityOptions: Observable<Speciality[]>;

  groupFilterLabels = {
    speciality: '__specialities',
    practitioner: '__doctor',
    practice: customPracticeLabel ?? '__practice_title'
  };

  homeLoginDoctenaAccount = homeLoginDoctenaAccount;
  consultationGroup: UntypedFormGroup;
  showPatientLoginForm: boolean;
  isLogged = false;
  bingliFeature = false;
  showBingliForm = false;
  isBookingRulesSelected = false;
  groupFeatures = [];
  isAccountRequestComplete = false;
  bingliDoctenaConsent = false;
  practiceName = '';
  practicePhone = '';
  hidePicture = this.route.snapshot.paramMap.get('showPicture') === '0';
  currentPage = 1;
  pageCount = 1;
  pageSize = 10;
  private currentParams: HttpParams;

  /**
   * Component's constructor
   *
   * @param _formBuilder
   * @param route
   * @param router
   * @param helpers
   * @param agendaService
   * @param translateService
   * @param trackingService
   * @param calendarService
   * @param reviewsService
   * @param utilitiesService
   * @param authenticationService
   * @param alertService
   * @param groupService
   */
  constructor(private _formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private helpers: HelpersService,
              private agendaService: AgendaService,
              public translateService: TranslateService,
              public trackingService: TrackingService,
              public calendarService: CalendarService,
              public reviewsService: ReviewsService,
              public utilitiesService: UtilitiesService,
              public authenticationService: AuthenticationService,
              public alertService: AlertService,
              public groupService: GroupService) {
    super();
    this.agendas = [];
    this.groupAgendas = [];
    this.specialitiesAliases = [];
    this.profileUrl = 'https://www.doctena.com';
  }

  ngOnInit(): void {
    if (!this.helpers.isGUID(this.groupEid)) {
      console.error('The URL is not valid. The group\'s id should be a GUID.');
      // Go to "Page not found"
      this.router.navigate([''], {relativeTo: this.route, skipLocationChange: true});
    }

    // Manage practice ordering
    this.managePracticeFilterOrdering();

    this.consultationGroup = this._formBuilder.group({isDoctenaUser: false});

    // Get bearer for the app
    this.authenticationService.authApp().subscribe(result => {
      if (result !== true) {
        // Login failed
        const alert = new Alert();
        alert.type = 'error';
        alert.text = '__title_error_occurred';
        this.alertService.setAlert(alert);
        return;
      }

      if (gatewayName) {
        this.utilitiesService.getGatewaySpecialitiesAliasName(gatewayName,
          response => this.getGatewaySpecialitiesAliasNameCallback(response), error => this.getGatewaySpecialitiesAliasNameError());
        this.utilitiesService.getGatewayCustomLabels(gatewayName,
          response => this.getGatewayCustomLabelsCallback(response), error => this.getGatewayCustomLabelsError());
        return;
      }

      // Init group data
      this.getGroupAgendas();
    });

    this.initSearchForm();
  }

  private managePracticeFilterOrdering() {
    if (practiceOrdering) {
      switch (lowerCase(practiceOrdering)) {
        case 'alphabetical':
          this.practiceSortOrder = 'asc';
          this.practiceOrderProperty = ['name'];
          break;
        default:
          break;
      }
    }
  }

  /**
   * Get group agendas depending of params
   *
   * @private
   */
  private getGroupAgendas() {
    // Get group agendas
    const languageCode = this.translateService.currentLang;
    let params = new HttpParams(); // Add params of select rules if we have i
    params = params.append('language', languageCode);

    params = this.getFilterParams(params);

    this.policyUrl = environment.policy[languageCode];
    this.groupService.getGroupAgendasWithFeatures(this.groupEid, params)
      .then(result => this.getGroupAgendasCallback(result, params))
      .catch(error => console.error(error));
  }

  /**
   * Return filter params
   *
   * @param params
   * @private
   */
  getFilterParams(params: HttpParams = null) {
    if (!params) {
      params = new HttpParams();
    }

    if (this.selectedPractice) {
      params = params.append('practice', this.selectedPractice.id);
    }

    if (this.selectedDoctor) {
      params = params.append('doctor', this.selectedDoctor.id);
    }

    if (this.selectedSpeciality) {
      params = params.append('speciality', this.selectedSpeciality.id);
    }

    return params;
  }

  /**
   * Callback for groupAgendas call
   *
   * @param response
   * @param {HttpParams} params
   */
  private async getGroupAgendasCallback(response: any, params: HttpParams) {
    this.countryIso = response.groupCountryIso;
    this.groupFeatures = response.features;
    this.calendarService.availableFeatures = response.features;
    this.currentParams = params;

    // Filter by features
    if (this.groupFeatures) {
      // Feature Bingli
      if (this.groupFeatures.hasOwnProperty('triage') && this.groupFeatures['triage'].hasOwnProperty('client_ref')) {
        // If group using bingli feature, we'll display the bingli survey
        if (!this.bingliFeature) {
          this.bingliFeature = true;
          this.completeBingliSurvey = false;
        }
      } else {
        // In case of wrong setup (ROV and self service) -  no bingli feature, but the rov have triageKey
        // create a central check for both
        this.completeBingliSurvey = true;
      }
    }
    const hasAgendas = ((response.groupAgendas || typeof response.groupAgendas !== 'undefined') && response.groupAgendas.length > 0);
    if (hasAgendas) {
      let groupAgendas = response.groupAgendas;

      // If url params contains ignoreDoctors parameter, we exclude
      // the designated doctors from the list of doctors (using their ids)
      if (ignoreDoctors) {
        const ignoredEids = ignoreDoctors.split(',');
        groupAgendas = groupAgendas.filter(agenda =>
          ignoredEids.indexOf('' + agenda.doctor.externalId) === -1 // The quotes are for string comparison
        );
      }

      this.groupAgendas = groupAgendas;
      this.agendas = [];
      this.doctors = [];
      this.practices = [];
      this.specialities = [];

      await this.getAgendasData(params);

      this.initSearchForm();

      // Load reviews data with doctor eids
      const doctorsEids = response.groupAgendas.map(agenda => agenda.doctor.externalId);
      this.reviewsService.getReviews(doctorsEids)
        .then(result => this.getReviewsCallback(result))
        .catch(error => console.error(error));

      if (this.preSelectedPractice || this.preSelectedDoctor || this.preSelectedSpeciality) {
        const specFound = this.specialities.find(spec => spec.externalId === this.preSelectedSpeciality),
          docFound = this.doctors.find(doc => doc.externalId === this.preSelectedDoctor),
          pracFound = this.practices.find(prac => prac.externalId === this.preSelectedPractice);

        if (specFound) {
          this.selectedSpeciality = specFound;
          this.specialityControl.patchValue(specFound);
          this.preSelectedSpeciality = null;
        }

        if (docFound) {
          this.selectedDoctor = docFound;
          this.doctorControl.patchValue(docFound);
          this.preSelectedDoctor = null;
        }

        if (pracFound) {
          this.selectedPractice = pracFound;
          this.practiceControl.patchValue(pracFound);
          this.preSelectedPractice = null;
        }

        // Get group's agendas filtered
        return this.getGroupAgendas();
      }

      return this.loadAgendasPage();
    }
  }

  /**
   * Get agendas data
   *
   * @param params
   * @private
   */
  private getAgendasData(params: HttpParams) {
    // Get agenda for every practiceAgenda and once it's done, send request for reviews
    return BlueBirdPromise.each(this.groupAgendas, (agenda) => {

      // Get all doctors in group
      const foundDoctor = this.doctors.find(doctor => doctor.id === agenda.doctor.id);
      if (!foundDoctor) {
        this.doctors.push(agenda.doctor);
      }

      // Get all practices in group
      const foundSPractice = this.practices.find(practice => practice.id === agenda.practice.id);
      this.practiceName = agenda.practice.name;
      this.practicePhone = agenda.practice.phone;
      if (!foundSPractice) {
        this.practices.push(agenda.practice);
      }

      // Get all specialities from the group
      return BlueBirdPromise.each(agenda.specialities, (doctorSpeciality: Speciality) => {
        const foundSpeciality = this.specialities.find(spec => spec.id === doctorSpeciality.id);
        if (!foundSpeciality) {
          // Check if we have specialities aliases
          if (this.specialitiesAliases.length > 0) {
            const specialityAlias = this.specialitiesAliases.find(alias => alias.eid === doctorSpeciality.externalId);
            doctorSpeciality.name = specialityAlias ? (specialityAlias[this.translateService.currentLang] ?? doctorSpeciality.name) :
              doctorSpeciality.name;
          }

          this.specialities.push(doctorSpeciality);
        }
      });
    })
      .catch(error => console.error(error));
  }

  /**
   * Filter agendas
   *
   * @returns {any[]}
   */
  agendasFiltered(): any[] {
    return this.groupAgendas.filter((agenda: Agenda) => {
      if (this.calendarService.bookingParams['reasonOfVisit'] && this.calendarService.bookingParams['reasonOfVisit'].length > 0) {
        return typeof this.calendarService.bookingParams['reasonOfVisit'].find((reason: any) => agenda.externalId === reason.agendaEid) !== 'undefined';
      } else {
        return true;
      }
    });
  }

  /**
   * Load list for doctors
   */
  loadAgendasPage(event?: PageEvent) {
    const tempAgendas = [];
    this.calendarService.agendas = [];
    // Get page to load
    if (event) {
      this.currentPage = (event.pageIndex + 1);
      this.pageSize = event.pageSize;
    }

    const offset = (this.currentPage - 1) * this.pageSize,
      items = this.agendasFiltered().slice(offset, offset + this.pageSize);

    return BlueBirdPromise.map(items, (item: Agenda) => {
      return this.agendaService.getAgenda(
        item.externalId,
        this.currentParams
      ).then((agenda: Agenda) => {
        this.getAgendasCallback(agenda);
        tempAgendas.push(agenda);
      });
    })
      .then(async () => {
        this.agendas = [...tempAgendas];
        this.pageCount = Math.ceil(items.length / this.pageSize);

        // If we have a reason of visit, we need to get availabilities
        if (this.calendarService.bookingParams['reasonOfVisit'] && this.calendarService.bookingParams['reasonOfVisit'].length > 0) {
          await this.calendarService.getAvailabilities();
          this.agendas = [...tempAgendas];
        }
      });

  }

  /**
   * Callback method for agenda call
   *
   * @param {Agenda} agenda
   */
  private getAgendasCallback(agenda: Agenda) {
    if (agenda.id || typeof agenda.id !== 'undefined') {
      this.calendarService.addAgenda(agenda);
      agenda.doctor.mainSpeciality = agenda.mainSpeciality;
      this.profileUrl = this.calendarService.urlProfile('group');
      if (this.trackingService.selectedAgenda === null) {
        this.trackingService.selectedAgenda = agenda;
      }

      this.trackingService.eventRequestWidgetLoaded('group');
    }
  }

  /**
   * To know if the user filter data or not
   *
   * @private
   */
  private isFiltered() {
    return this.selectedSpeciality || this.selectedDoctor || this.selectedPractice;
  }

  /**
   * To know if we can display agenda's list
   */
  isDisplayable() {
    return this.agendas.length > 0;
  }

  /**
   * If the URL has an agendaId in its route Params, returns true
   */
  isInitiatedByDoctorView() {
    return this.route.snapshot.params.agendaId;
  }

  /**
   * If the initial view is a doctorView, we can go back to it by calling this function
   */
  goBackToDoctorView() {
    this.router.navigate([
      this.route.snapshot.parent.paramMap.get('language'),
      'doctor',
      this.route.snapshot.params.agendaId
    ]);
  }

  /**
   * Callback for reviews call
   *
   * @param response
   * @private
   */
  private getReviewsCallback(response) {
    this.reviewsService.storeReviews(response);
  }

  /**
   * Init search form
   *
   * @private
   */
  private initSearchForm() {
    this.specialityOptions = this.specialityControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this.filterSearchOptions(name, this.specialities) : this.specialities.slice())
      );

    this.doctorOptions = this.doctorControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this.filterSearchOptions(name, this.doctors) : this.doctors.slice())
      );

    this.practiceOptions = this.practiceControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this.filterSearchOptions(name, this.practices) : this.practices.slice())
      );
  }

  /**
   * Display data in autocomplete input
   *
   * @param data
   */
  displayOptions(data: Doctor | Speciality | Practice): string {
    return data && data.name ? data.name : '';
  }

  /**
   * Filter for options in search form
   *
   * @param name
   * @param options
   * @private
   */
  private filterSearchOptions(name: string, options: any[]) {
    const filterValue = name.toLowerCase();
    return options.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  /**
   * Event when a speciality is selected
   *
   * @param event
   */
  onSpecialitySelect(event: MatAutocompleteSelectedEvent) {
    this.selectedSpeciality = event.option.value;

    // Get group's agendas filtered
    this.getGroupAgendas();
  }

  /**
   * Event when a doctor is selected
   *
   * @param event
   */
  onDoctorSelect(event: MatAutocompleteSelectedEvent) {
    this.selectedDoctor = event.option.value;

    // Get group's agendas filtered
    this.getGroupAgendas();
  }

  /**
   * Event when a practice is selected
   *
   * @param event
   */
  onPracticeSelect(event: MatAutocompleteSelectedEvent) {
    this.selectedPractice = event.option.value; // Get group's agendas filtered
    this.getGroupAgendas();
  }

  /**
   * Reset search filters
   */
  onResetSearchFiltersClick(value) {

    // reset all variable related to Bingli
    if (this.showBingliForm) {
      this.showBingliForm = false;
      this.completeBingliSurvey = false;
      this.isBingliRovSelected = false;
      this.bingliDoctenaConsent = false;
    }

    switch (value) {
      case 'speciality':
        this.selectedSpeciality = null;
        this.specialityControl.patchValue('');
        break;
      case 'doctor':
        this.selectedDoctor = null;
        this.doctorControl.patchValue('');
        break;
      case 'practice':
        this.selectedPractice = null;
        this.practiceControl.patchValue('');
        break;
    }
    // Reset data
    this.getGroupAgendas();
  }

  /**
   * utilisesService - getGatewaySpecialitiesAliasName callback
   *
   * @param specialitiesAliases
   * @private
   */
  private getGatewaySpecialitiesAliasNameCallback(specialitiesAliases: Array<any>) {
    if (specialitiesAliases && specialitiesAliases.length > 0) {
      this.specialitiesAliases = specialitiesAliases;
    }

    // Init group data
    this.getGroupAgendas();
  }

  /**
   * utilisesService - getGatewaySpecialitiesAliasName callback
   *
   * @param customLabels
   * @private
   */
  private getGatewayCustomLabelsCallback(customLabels: any) {
    Object.keys(this.groupFilterLabels).forEach(labelType => {
      if (customLabels[labelType]) {
        const languageCode = this.translateService.currentLang;
        this.groupFilterLabels[labelType] = customLabels[labelType][languageCode] ?? customLabels[labelType]['en'];
      }
    });
  }

  /**
   * utilisesService - getGatewaySpecialitiesAliasName error callback
   *
   * @private
   */
  private getGatewaySpecialitiesAliasNameError() {
    const alert = new Alert();
    alert.type = 'warning';
    alert.text = '__title_error_occurred';
    this.alertService.setAlert(alert);

    // Init group data
    this.getGroupAgendas();
  }

  /**
   * utilisesService - getGatewayCustomLabels error callback
   *
   * @private
   */
  private getGatewayCustomLabelsError() {
    const alert = new Alert();
    alert.type = 'warning';
    alert.text = '__title_error_occurred';
    this.alertService.setAlert(alert);

    // Init group data
    this.getGroupAgendas();
  }

  /** Manage the patient login button */
  isDoctenaUser(status) {
    if (status) {
      this.showPatientLoginForm = true;
      return this.consultationGroup.patchValue({isDoctenaUser: true});
    }
    this.showPatientLoginForm = false;
    this.isAccountRequestComplete = true;
    return this.consultationGroup.patchValue({isDoctenaUser: false});
  }

  setIsLogged(isLogged: boolean) {
    this.isLogged = isLogged;
    this.showPatientLoginForm = !this.showPatientLoginForm;
  }

  isPreSelect(value) {
    return !!((value === 'practice') &&
      this.selectedPractice ||
      (value === 'doctor') &&
      this.selectedDoctor ||
      (value === 'speciality') &&
      this.selectedSpeciality);
  }

  /**
   * Check if booking rules are all selected
   * @param status
   * @private
   */
  setStatusBookingRulesSelected(status: boolean) {
    this.isBookingRulesSelected = status;
    if (status) {
      this.showBingliForm = true;
    }
  }

  // Manage the patient login button
  showPatientLogin() {
    return !(this.bingliFeature && this.isBookingRulesSelected) && homeLoginDoctenaAccount;
  }

  consentBingliDoctena() {
    this.bingliDoctenaConsent = true;
  }

  hideCookieBanner() {
    return hideCookieBanner;
  }
}
