// COMPONENTS
import {AppComponent} from './app.component';
import {FiltersComponent} from './filters/filters.component';
import {ListComponent} from './list/list.component';
import {DoctorDetailsComponent} from './doctor-details/doctor-details.component';
import {WeekSelectorComponent} from './week-selector/week-selector.component';
import {CalendarComponent} from './calendar/calendar.component';
import {PracticeComponent} from './practice/practice.component';
import {DoctorComponent} from './doctor/doctor.component';
import {AlertComponent} from './alert/alert.component';
import {LoaderComponent} from './loader/loader.component';
import {FieldsetComponent} from './fieldset/fieldset.component';
import {FieldComponent} from './fieldset/field.component';
import {PatientLoginComponent} from './patient-login/patient-login.component';
import {BingliFormComponent} from './bingli-form/bingli-form.component';

// MODULES
import {BrowserModule} from '@angular/platform-browser';
import {importProvidersFrom, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatListModule} from '@angular/material/list';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatStepperModule} from '@angular/material/stepper';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {Nl2brPipe} from './pipe/nl2br/nl2br.pipe';

// SERVICES
import {AlertService} from './service/alert/alert.service';
import {LoaderService} from './service/loader/loader.service';
import {HttpInterceptorService} from './service/http-interceptor/http-interceptor.service';
import {HelpersService} from './service/helpers/helpers.service';
import {HttpService} from './service/http/http.service';
import {FiltersService} from './service/filters/filters.service';
import {PatientService} from './service/patient/patient.service';
import {DoctorService} from './service/doctor/doctor.service';
import {PracticeService} from './service/practice/practice.service';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AgendaService} from './service/agenda/agenda.service';
import {CalendarService} from './service/calendar/calendar.service';
import {BookingService} from './service/booking/booking.service';
import {CapitalizePipe} from './pipe/capitalize/capitalize.pipe';
import {AuthenticationService} from './service/authentication/authentication.service';
import {TrackingService} from './service/tracking/tracking.service';
import {ReviewsService} from './service/reviews/reviews.service';
import {CookieService} from './service/cookie/cookie.service';
import {BingliService} from './service/bingli/bingli.service';

import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {OrderPracticeAgendasPipe} from './pipe/order-practice-agendas/order-practice-agendas.pipe';
import {AppointmentService} from './service/appointment/appointment.service';
import {RescheduleComponent} from './reschedule/reschedule.component';
import {RoutingComponent} from './routing/routing.component';
import {RatingsComponent} from './ratings/ratings.component';
import {SprintfPipe} from './pipe/sprintf/sprintf.pipe';
import {TransitionGroupDirective, TransitionGroupItemDirective} from './directive/transition-group.directive';
import {OrderRovPipe} from './pipe/order-rov/order-rov.pipe';
import {CookieInfoComponent} from './cookie-info/cookie-info.component';
import {MomentPipe} from './pipe/moment/moment.pipe';
import {BookingModalComponent} from './booking-modal/booking-modal.component';
import {GroupComponent} from './group/group.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {TranslatePipe} from './pipe/translate/translate.pipe';
import {BingliTrait} from './trait/bingli.trait';
import {SortPipe} from './pipe/sort/sort.pipe';
import {FileUploadComponent} from './file-upload/file-upload.component';
import {BookingStepsComponent} from './booking-steps/booking-steps.component';
import {BookingConsultationStepComponent} from './booking-consultation-step/booking-consultation-step.component';
import {MatPaginator} from '@angular/material/paginator';
import {NgOptimizedImage} from '@angular/common';
import {MatLine} from '@angular/material/core';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './locale/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    FiltersComponent,
    ListComponent,
    DoctorDetailsComponent,
    WeekSelectorComponent,
    PracticeComponent,
    DoctorComponent,
    Nl2brPipe,
    CapitalizePipe,
    PageNotFoundComponent,
    OrderPracticeAgendasPipe,
    RescheduleComponent,
    RoutingComponent,
    RatingsComponent,
    TransitionGroupDirective,
    TransitionGroupItemDirective,
    OrderRovPipe,
    CookieInfoComponent,
    BookingModalComponent,
    GroupComponent,
    BingliFormComponent,
    SortPipe
  ],
  bootstrap: [AppComponent],
  exports: [
    BookingModalComponent,
    MomentPipe
  ], imports: [BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    MatMenuModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatListModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatStepperModule,
    MatRadioModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    CalendarComponent,
    MomentPipe,
    FileUploadComponent,
    BookingStepsComponent,
    AlertComponent,
    TranslatePipe,
    LoaderComponent,
    BookingConsultationStepComponent,
    SprintfPipe,
    PatientLoginComponent,
    FieldsetComponent,
    FieldComponent,
    MatPaginator,
    NgOptimizedImage,
    MatLine
  ], providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    provideHttpClient(),
    importProvidersFrom(TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })),
    AlertService,
    HttpInterceptorService,
    LoaderService,
    HelpersService,
    HttpService,
    DoctorService,
    PatientService,
    FiltersService,
    PracticeService,
    AgendaService,
    CalendarService,
    BookingService,
    AuthenticationService,
    AppointmentService,
    TrackingService,
    ReviewsService,
    CookieService,
    BingliService,
    BingliTrait,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {
  constructor(
    public alertService: AlertService,
    public bookingService: BookingService,
    public translateService: TranslateService
  ) {
  }
}
