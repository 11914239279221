export class Appointment {
  externalId: string;
  agenda_externalId: string;
  id: number;
  status: number;
  insurance: number;
  customQuestionsAnswers: QuestionAnswer[];
  createdateTimestamp: string;
  userFirstName: string;
  userLastName: string;
  userEmail: string;
  userMobile: string;
  userGender: string;
  patientFirstName: string;
  patientLastName: string;
  patientGender: string;
  reason: string;
  street: string;
  zipCode: string;
  houseNumber: string;
  address: any;
  patientNote: string;
  returningPatient: boolean;
  doctor_id: number;
  doctor_externalId: string;
  agenda_id: number;
  patient_id: number;
  endTimestamp: string;
  startTimestamp: string;
  updatedate: Date;
  createdate: Date;
  userBirthday: any;
  patientBirthday: any;
  updatedateTimestamp: string;
  doctorReason: any;
  start: Date;
  end: Date;
  type: Appointment.TypeEnum;
  matricule?: string;
  matriculeIso?: string;
  apiClientId?: string;
}

export class QuestionAnswer {
  answer: {
    value: any;
  };
  question_eid: string;
  label?: string;
  order?: number;
  placeholder?: string;
}

export namespace Appointment {
  export enum TypeEnum {
    Normal = 1,
    TimeFrame = 2
  }
}
