import BlueBirdPromise from 'bluebird';
import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {HttpService} from '../http/http.service';
import {Review} from '../../models/review';
import {Observable, Subject} from 'rxjs';

@Injectable()
export class ReviewsService extends HttpService {

  // Allow other components or service to subscribe to reviews change
  reviews$: Observable<Array<Review>>;
  interval: any;
  private reviewsSubject: Subject<Array<Review>>;

  constructor(http: HttpClient) {
    super(http);

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    this.reviewsSubject = new Subject<Array<Review>>();
    this.reviews$ = this.reviewsSubject.asObservable();
  }

  /**
   * Call to german micro-service for reviews
   *
   * @param eids
   */
  getReviews(eids: string[]) {
    return new BlueBirdPromise<any>((resolve, reject) => {
      const apiUrl = this.ratingsApiUrl + 'doctors/list?eids=';
      return this.get(apiUrl + eids.join())
        .subscribe({
          next: (data) => resolve(data),
          error: (error) => reject(error)
        });
    });
  }

  /**
   * Store results of get request reviews after stripping useless data
   *
   * @param data
   */
  storeReviews(data) {
    const reviews = [];
    // In case of empty data
    if (Array.isArray(data) && data.length === 0) {
      return;
    }
    // We put in our reviews array only the data that we need.
    // We make it an associative array so we can easily get the data using the doctorEid
    data.message.forEach(doctor => {
      const {showRatings, averageRating, recommendationsCount, ratingsCount, doctorEid} = doctor;
      const review = {showRatings, averageRating, recommendationsCount, ratingsCount};
      reviews[doctorEid] = review;
    });

    this.interval = setInterval(() => {
      this.reviewsSubject.next(reviews);
    }, 500);
  }
}
