import {Injectable} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {DateTime} from 'luxon';
import {HttpParams} from '@angular/common/http';
import {AgendaService} from '../agenda/agenda.service';
import {Agenda} from '../../models/agenda';
import {Slot} from '../../models/slot';
import {CustomMessage} from '../../models/customMessage';
import {CustomFieldsResponse} from '../../models/customFields';
import {Appointment} from '../../models/appointment';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs/internal/Observable';
import {Subject} from 'rxjs/internal/Subject';
import async from 'async';
import {showMessageNotificationWebNoSlot} from '../../routing/routing.component';

@Injectable()
export class CalendarService {

  today = DateTime.now();
  firstLoad: boolean;
  selectedDay = new UntypedFormControl({
    value: DateTime.now(),
    disabled: true // Disable input editing
  });

  weekDays: Array<any>;
  // Allow other components or service to subscribe to reviews change
  weekDays$: Observable<Array<any>>;
  private weekDaysSubject: Subject<Array<any>>;

  nbDays: number;
  agendas: Array<Agenda>;

  slots: any;
  slotsSubject: Subject<any>;
  slots$: Observable<any>;

  shadowContainers: Array<any>;
  nextSlot: any;
  selectedSlot: Slot;
  selectedAgenda: Agenda;
  private _bookingParams: any;
  bookingParamsSubject: Subject<any>;
  bookingParams$: Observable<any>;
  customMessage?: CustomMessage;
  customFields?: CustomFieldsResponse;
  hasBookingRules = false;
  hasReasonsOfVisits = false;
  appointmentReschedule: Appointment;
  firstComponent: string;
  filtersFilled: boolean;
  notificationMessage = [];
  languageIso: string;
  availableFeatures: any;


  /**
   * Get booking params
   *
   * @returns {any}
   */
  get bookingParams(): any {
    return this._bookingParams;
  }

  /**
   * Set booking params
   *
   * @param value
   */
  set bookingParams(value: any) {
    this._bookingParams = value;
    // Notify subscribers
    this.bookingParamsSubject.next(this._bookingParams);
  }

  constructor(private agendaService: AgendaService,
              public translateService: TranslateService) {
    this.slots = [];
    this.slotsSubject = new Subject<Array<any>>();
    this.slots$ = this.slotsSubject.asObservable();
    this.bookingParamsSubject = new Subject<any>();
    this.bookingParams$ = this.bookingParamsSubject.asObservable();

    this.weekDaysSubject = new Subject<Array<any>>();
    this.weekDays$ = this.weekDaysSubject.asObservable();

    this.shadowContainers = [];
    this.agendas = [];
    this.customFields = {};
    this.nextSlot = [];
    this._bookingParams = [];
    this.setCalendarFormat(window.innerWidth);
    this.firstComponent = '';
    this.availableFeatures = {};
  }

  /**
   * Add agenda into the list
   *
   * @param {Agenda} agenda
   */
  addAgenda(agenda: Agenda) {
    this.slots[agenda.externalId] = [];
    this.nextSlot[agenda.externalId] = null;
    this.bookingParams[agenda.externalId] = [];
    this.agendas.push(agenda);
  }

  /**
   * Get an array of date in the current select week
   *
   * @returns {Array<Date>}
   */
  getWeekDaysCalendar() {
    return this.momentsToDate(this.weekDays);
  }

  /**
   * Action when the user change the date
   *
   * @param date
   */
  changeDate(date) {
    // Update datepicker if the change has been made otherwise
    if (this.selectedDay.value !== date) {
      this.selectedDay.patchValue(date);
    }

    this.setWeekDays(date, this.nbDays);
    this.getAvailabilities();
  }

  /**
   * Adapt the time management to the window size
   *
   * @param windowSize
   */
  setCalendarFormat(windowSize) {
    let newNbDays;
    switch (true) {
      case (windowSize >= 1200): {
        newNbDays = 7;
        break;
      }
      case (windowSize >= 992 && windowSize < 1200): {
        newNbDays = 5;
        break;
      }
      case (windowSize >= 768 && windowSize < 992): {
        newNbDays = 4;
        break;
      }
      case (windowSize >= 480 && windowSize < 768): {
        newNbDays = 5;
        break;
      }
      case (windowSize >= 400 && windowSize < 480): {
        newNbDays = 4;
        break;
      }
      default: {
        newNbDays = 3;
        break;
      }
    }

    if (newNbDays !== this.nbDays) {
      this.setWeekDays(this.selectedDay.value, newNbDays, newNbDays < this.nbDays);
      this.nbDays = newNbDays;
    }
  }

  /**
   * Display the right dates in the week view, according to what day is selected
   *
   * @param firstDay
   * @param {number} nbDays
   * @param {boolean} decreasing
   */
  setWeekDays(firstDay, nbDays: number, decreasing?: boolean) {
    let weekDays = [];
    if (decreasing) {
      // If the number of days is decreasing, we just shorten the array
      weekDays = this.weekDays.slice(0, nbDays);
    } else {
      // Otherwise, we calculate the week days again
      for (let i = 0; i < nbDays; ++i) {
        weekDays[i] = firstDay.plus({days: i}); // we use the moment constructor again to clone the object
      }
    }

    this.weekDays = weekDays;
    this.weekDaysSubject.next(weekDays);
  }

  /**
   * Transform array of Moment dates to array of Date object
   *
   * @param {Array<any>} days
   * @returns {Array<Date>}
   */
  private momentsToDate(days: Array<any>): Array<Date> {
    const newDays = [];
    days.forEach(function (day, key) {
      newDays.push(day.toDate());
    });

    return newDays;
  }

  /**
   * Get availabilities for all agendas depending of params form filter component
   */
  getAvailabilities() {
    const params = this.prepareAvailabilitiesParams();

    // Get availabilities for every agenda
    return async.map(this.agendas,
      (agenda, callback) => {
        this.getAvailabilitiesByAgenda(agenda, params, callback);
      },
      (err, results) => { // After every availability are loaded
        if (err) {
          console.error(err);
        }
      }
    );
  }

  /**
   * Reset availabilities for all agendas
   */
  resetAvailabilities() {
    // Reset availabilities for every agenda
    async.map(this.agendas,
      (agenda) => {
        this.slots[agenda.externalId] = [];
        this.nextSlot[agenda.externalId] = null;
      },
      (err, results) => {
        if (err) {
          console.error(err);
        }
      }
    );
  }

  /**
   * Get slots store in service for a specific agenda
   *
   * @param {Agenda} agenda
   * @returns {Array<Slot>}
   */
  getSlotsByAgenda(agenda: Agenda): Array<Slot> {
    if (typeof this.slots[agenda.externalId] === 'undefined') {
      return [];
    }

    return this.slots[agenda.externalId];
  }

  /**
   * Get the value of the next availability for an agenda
   *
   * @param {Agenda} agenda
   * @returns {Date}
   */
  getNextSlotByAgenda(agenda: Agenda): any {
    return this.nextSlot[agenda.externalId];
  }

  /**
   * Get custom message from doctor, to display either inside the calendar view
   * or inside the booking view
   *
   * @param agendaEid  string  eid of the selected agenda
   * @param rovEid  string  eid of the selected visit reason
   * @param languageIso  string  user language iso
   * @param callback
   */
  getCustomMessage(agendaEid: string, rovEid: string, languageIso: string, callback?: (data: any) => void) {
    let params = new HttpParams(); // Add params of select rules if we have it
    if (rovEid) {
      params = params.append('visitReasonEids', rovEid);
    }
    params = params.append('language', languageIso);

    this.agendaService.getCustomMessage(agendaEid, params, (response) => {
      if (response.notifications) {
        this.customMessage = response.notifications[0];
        if (callback) {
          callback(this.customMessage);
        }
      }
    });
  }

  /**
   * Get custom fields to display inside the booking page, for a specific agenda.
   *
   * @param agendaEid  string  external id of the selected agenda
   * @param slotType  number  type number of the selected slot
   * @param visitReasonEid  string  external id of the selected visit reason
   * @param languageIso  string  iso code for the language selected
   * @param callback  function
   */
  getCustomFields(agendaEid: string, slotType: number, visitReasonEid: string, languageIso: string, callback?: (data: any) => void) {
    let params = new HttpParams(); // Add params of select rules if we have it

    if (visitReasonEid) {
      params = params.append('visitReasonEid', visitReasonEid);
    }

    if (languageIso) {
      params = params.append('language', languageIso);
    }

    if (slotType) {
      params = params.append('slotType', slotType.toString());
    }

    this.agendaService.getCustomFields(agendaEid, params, (response) => {
      this.customFields = {};
      if (response.agendaCustomFields) {
        this.customFields = response.agendaCustomFields;
      }

      if (callback) {
        callback(this.customFields);
      }
    });
  }

  /**
   * Prepare params to get availabilities
   *
   * @returns {HttpParams}
   */
  prepareAvailabilitiesParams(): HttpParams {
    // const from = this.weekDays[0].format('x').substring(0, 10);
    const fromDate = this.weekDays[0].set({hour: 0, minute: 0, second: 1}),
      toDate = this.weekDays[this.weekDays.length - 1].set({hour: 23, minute: 59, second: 59}),
      to = toDate.toFormat('X'),
      from = fromDate.toFormat('X');
    let params = new HttpParams(); // Add params of select rules if we have it

    // Add language if we have it for agenda message translation
    if (this.languageIso) {
      params = params.append('language', this.languageIso);
    }

    params = params.append('from', from);
    params = params.append('to', to);

    return params;
  }

  /**
   * Set booking params from filters data
   *
   * @param {Agenda} agenda
   * @param {HttpParams} params
   * @returns {HttpParams}
   */
  setFilterBookingParams(agenda: Agenda, params: HttpParams): HttpParams {
    Object.keys(this._bookingParams).forEach((keyName, index) => {
      switch (keyName) {
        case 'reasonOfVisit': {
          if (this._bookingParams['reasonOfVisit'] !== true) {
            this._bookingParams['reasonOfVisit'].forEach((reason, key) => {
              if (agenda.externalId === reason.agendaEid) {
                params = params.append('reason', reason.id);
              }
            });
          }
          break;
        }
        case 'bookingRules': {
          this._bookingParams['bookingRules'].forEach((bookingRule, key) => {
            params = params.append(bookingRule.name, bookingRule.selectedValue);
          });
          break;
        }
        default: {
          break;
        }
      }
    });

    return params;
  }

  /**
   * Method to get availabilities by agenda depending some params
   */
  getAvailabilitiesByAgenda(agenda: Agenda, params: HttpParams, callback?: Function) {
    this.slots[agenda.externalId] = [];
    this.nextSlot[agenda.externalId] = null;

    // Get availabilities only for agenda corresponding to the right ROV
    if (Array.isArray(this._bookingParams['reasonOfVisit']) &&
      (typeof this._bookingParams['reasonOfVisit'].find(reason => agenda.externalId === reason.agendaEid) !== 'undefined')) {
      params = this.setFilterBookingParams(agenda, params);

      this.agendaService.getAgendaAvailabilities(agenda.externalId, showMessageNotificationWebNoSlot,
        response => this.getAgendaAvailabilitiesCallback(response, agenda, callback), params);
    }
  }

  /**
   * Callback for reason of visits call
   *
   * @param response
   * @param agenda
   * @param callback
   */
  private getAgendaAvailabilitiesCallback(response: any, agenda: Agenda, callback?: Function) {
    const {nextSlotTimestamp, slots, uri, notificationMessage} = response;

    // return false if its not in the APi response
    this.notificationMessage[agenda.externalId] = notificationMessage ?? false;

    if (!this.firstLoad) {
      this.firstLoad = true;
    }

    if (nextSlotTimestamp) {
      // Convert timestamp to Date object
      this.nextSlot[agenda.externalId] = DateTime.fromSeconds(parseInt(nextSlotTimestamp));
    }

    if (slots) {
      this.slots[agenda.externalId] = this.initSlotsObject(slots);
    }

    this.slotsSubject.next({slots: this.slots, nextSlots: this.nextSlot});

    if (callback) {
      callback(null, {nextSlotTimestamp, slots, uri, notificationMessage});
    }
  }

  /**
   * Transform date in slots to Date object
   *
   * @param slots
   * @returns {any}
   */
  initSlotsObject(slots) {
    slots.forEach(function (slot, key) {
      slot.start = DateTime.fromISO(slot.start.date);
      slot.end = DateTime.fromISO(slot.end.date);
    });
    return slots;
  }


  resetFilters() {
    this.hasBookingRules = false;
    this.hasReasonsOfVisits = false;
    this.bookingParams = [];
    this.customMessage = undefined;
  }

  /**
   * Create url for Doctena profile
   */
  urlProfile(profile) {
    let env = environment.environmentName;
    const countryIso = this.agendas[0].practice.country_iso;
    const doctorPublicUri = this.agendas[0].doctor.publicUri;
    const nameProfile = (profile === 'doctor') ? doctorPublicUri.split('/')[0] : doctorPublicUri.split('/')[1];
    if ((env.length === 0) && (countryIso === 'at')) {
      env = 'patient';
    }
    env = (env.length > 0) ? '.' + env : '';
    return 'https://www' +
      env +
      '.doctena.' + countryIso +
      '/' + profile + '/' + nameProfile;
  }
}
