import BlueBirdPromise from 'bluebird';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {HttpService} from '../http/http.service';

@Injectable()
export class AgendaService extends HttpService {

  constructor(http: HttpClient) {
    super(http);
  }

  /**
   * Get agenda by external id.
   *
   * @param {string} eid
   * @param {HttpParams} params
   * @returns {any}
   */
  getAgenda(eid: string, params?: HttpParams) {

    return new BlueBirdPromise<any>((resolve, reject) => {
      if (typeof params === 'undefined') {
        params = new HttpParams();
      }

      params = params.append('weight', 'large_without_slot');

      return this.get(this.apiUrl + '/agendas/' + eid, params)
        .subscribe({
          next: (data) => {

            // Add webBookingText data to agendas object
            const doctor = ((data || {})._embedded || {}).doctor;
            data.webBookingText = (doctor || {}).webBookingText;
            resolve(this.stripUselessData(data));
          },
          error: (error) => reject(error)
        });
    });
  }

  getAgendaAvailabilities(eid, isShowMessageNotificationWebNoSlot = false, callback, params?: HttpParams) {
    if (typeof params === 'undefined') {
      params = new HttpParams();
    }

    params = params.append('weight', 'front_search_change_date');
    params = params.append('showMessageNotification', isShowMessageNotificationWebNoSlot);

    this.get(this.apiUrl + '/agendaAvailabilities/' + eid, params)
      .subscribe(data => {
        callback(this.stripUselessData(data));
      });
  }

  getCustomMessage(agendaEid: string, params: HttpParams, callback) {
    return this.get(this.apiUrl + '/preBookingNotification/' + agendaEid, params).subscribe(callback);
  }

  /**
   * Get custom fields to display inside the booking page, for a specific agenda.
   *
   * @param agendaEid  string  external id of the selected agenda
   * @param params  HttpParams  query parameters for URL
   * @param callback  function
   */
  getCustomFields(agendaEid: string, params: HttpParams, callback) {
    return this.get(this.apiUrl + '/agenda/' + agendaEid + '/customFields', params).subscribe(callback);
  }

  /**
   * Get features for a specific agenda.
   *
   * @param eid
   * @param params
   */
  getAgendaWithFeatures(eid, params?: HttpParams) {
    return new BlueBirdPromise<any>((resolve, reject) => {
      if (typeof params === 'undefined') {
        params = new HttpParams();
      }
      params = params.append('weight', 'large_without_slot');
      return this.get(this.apiUrl + '/agendaWithFeatures/' + eid, params)
        .subscribe({
          next: (data) => {
            resolve(this.stripUselessData(data));
          },
          error: (error) => {
            reject(this.stripUselessData(error));
          }
        });
    });
  }
}
