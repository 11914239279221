import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import {HttpService} from '../http/http.service';
import {environment} from '../../../environments/environment';
import BlueBirdPromise from 'bluebird';

@Injectable()
export class DoctorService extends HttpService {

  constructor(http: HttpClient) {
    super(http);
  }

  /**
   * Call to get avatar picture from multi-picture service
   *
   * @param eid
   * @param callback
   * @param callbackError
   */
  getAvatarPicture(eid: string) {

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let params = new HttpParams(); // Add params of select rules if we have it
    params = params.append('structure', 'type');
    params = params.append('filter', 'profile');

    return new BlueBirdPromise((resolve, reject) => {
      this.get(environment.esiksUrl + '/eid/' + eid, params)
        .subscribe({
          next: (data) => {
            resolve(data);
          },
          error: (error) => reject(error)
        });
    });
  }
}
