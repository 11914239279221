import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {NgComponentOutlet, NgForOf, NgIf} from '@angular/common';
import {AlertComponent} from '../alert/alert.component';
import {LoaderComponent} from '../loader/loader.component';
import {CalendarService} from '../service/calendar/calendar.service';
import {MatStep, MatStepContent, MatStepLabel, MatStepper} from '@angular/material/stepper';
import {FormBuilder} from '@angular/forms';
import {BookingConsultationStepComponent} from '../booking-consultation-step/booking-consultation-step.component';
import {TranslatePipe} from '../pipe/translate/translate.pipe';
import {BookingInformationStepComponent} from '../booking-information-step/booking-information-step.component';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {AlertService} from '../service/alert/alert.service';
import {TrackingService} from '../service/tracking/tracking.service';
import {Appointment} from '../models/appointment';
import {Slot} from '../models/slot';
import {BookingService} from '../service/booking/booking.service';
import {BookingConfirmationStepComponent} from '../booking-confirmation-step/booking-confirmation-step.component';
import {userData} from '../routing/routing.component';
import {Agenda} from '../models/agenda';
import {DateTime} from 'luxon';
import {Alert} from '../models/alert';
import {TranslateService} from '@ngx-translate/core';
import {AppointmentService} from '../service/appointment/appointment.service';
import {HttpErrorResponse, HttpParams} from '@angular/common/http';
import {BookingFinishStepComponent} from '../booking-finish-step/booking-finish-step.component';
import {UtilitiesService} from '../service/utilities/utilities.service';
import {BookingFileUploadStepComponent} from '../booking-file-upload-step/booking-file-upload-step.component';
import {FileUploadService} from '../service/file-upload/file-upload.service';
import {LoaderService} from '../service/loader/loader.service';
import {AgendaService} from '../service/agenda/agenda.service';

@Component({
  selector: 'app-booking-steps',
  standalone: true,
  imports: [
    NgIf,
    AlertComponent,
    LoaderComponent,
    MatStepper,
    MatStep,
    NgForOf,
    MatStepLabel,
    TranslatePipe,
    NgComponentOutlet,
    MatStepContent
  ],
  templateUrl: './booking-steps.component.html',
  styleUrl: './booking-steps.component.scss'
})
export class BookingStepsComponent implements OnInit, AfterViewInit {

  /**
   * User data
   */
  userData = userData;

  /**
   * Appointment already accepted
   */
  _appointmentAlreadyAccepted = false;

  /**
   * Getter for appointmentAlreadyAccepted
   */
  get appointmentAlreadyAccepted() {
    return this._appointmentAlreadyAccepted;
  }

  /**
   * Setter for appointmentAlreadyAccepted
   *
   * @param value
   */
  set appointmentAlreadyAccepted(value: boolean) {
    this._appointmentAlreadyAccepted = value;
    const stepFinishedIndex = this.steppers.findIndex((step: any) => step.name === 'booking_finished');
    this.steppers[stepFinishedIndex].inputs.appointmentAlreadyAccepted = this.appointmentAlreadyAccepted;
  }

  steppers: any[] = [
    {
      name: 'booking_patient_info_step1',
      isOptional: false,
      component: BookingConsultationStepComponent,
      inputs: {
        formGroup: this.formBuilder.group({})
      },
      label: '__booking_patient_info_step1',
      action: (e: StepperSelectionEvent) => {
        this.generateFormData();
        if (!this.loaderOn) {
          this.currentStep = e.selectedIndex;
          this.trackingService.eventRequestBooking('booking_patient_info_step1');
        }
      },
      hasAppointmentData: true,
      waitBeforeNewStep: false
    },
    {
      name: 'booking_patient_info_step2',
      isOptional: false,
      component: BookingInformationStepComponent,
      inputs: {
        formGroup: this.formBuilder.group({})
      },
      label: '__booking_patient_info_step2',
      action: (e: StepperSelectionEvent) => {
        if (this.loaderOn) {
          return;
        }
        this.createAppointment(e);
      },
      hasAppointmentData: true,
      waitBeforeNewStep: true
    },
    {
      name: 'booking_verification',
      isOptional: false,
      component: BookingConfirmationStepComponent,
      inputs: {
        formGroup: this.formBuilder.group({})
      },
      label: '__booking_verification',
      action: (e: StepperSelectionEvent) => {
        if (this.loaderOn) {
          return;
        }

        if (this.bookingService.currentAppointment.status === 0) {
          this.loaderOn = true;
          this.confirmAppointment(e);
        }
      },
      hasAppointmentData: false,
      waitBeforeNewStep: true
    },
    {
      name: 'booking_finished',
      isOptional: false,
      component: BookingFinishStepComponent,
      inputs: {
        appointmentAlreadyAccepted: this.appointmentAlreadyAccepted
      },
      label: '__booking_finished',
      hasAppointmentData: false,
      waitBeforeNewStep: false
    }
  ];

  /**
   * Current step of the stepper
   */
  loaderOn: boolean;

  /**
   * Current step of the stepper
   */
  currentStep: number;

  /**
   * Survey ID
   */
  surveyId: string;

  /**
   * Is reschedule
   */
  isReschedule: boolean;

  /**
   * Selected reason
   */
  selectedReason: any;

  /**
   * System language ID
   */
  systemLanguageId: number;

  /**
   * Stepper component
   */
  @ViewChild('stepper', {static: false}) stepper: MatStepper;

  constructor(public calendarService: CalendarService,
              public alertService: AlertService,
              private agendaService: AgendaService,
              public bookingService: BookingService,
              public fileUploadService: FileUploadService,
              public appointmentService: AppointmentService,
              private formBuilder: FormBuilder,
              private loaderService: LoaderService,
              public utilitiesService: UtilitiesService,
              public translateService: TranslateService,
              public trackingService: TrackingService) {
    this.currentStep = 0;
    this.loaderOn = false;
    this.appointmentAlreadyAccepted = false;
  }

  /**
   * On init
   */
  ngOnInit(): void {
    if (!this.calendarService?.selectedAgenda?.externalId) {
      return;
    }

    this.isReschedule = (typeof this.calendarService.appointmentReschedule !== 'undefined');
    this.bookingService.currentAppointment = null;

    if (this.calendarService?.bookingParams['reasonOfVisit']) {
      this.calendarService.bookingParams['reasonOfVisit'].forEach((reason: any) => {
        if (this.calendarService.selectedAgenda.externalId === reason.agendaEid) {
          this.selectedReason = reason;
        }
      });
    }

    this.bookingService.getSystemLanguages(
      (response: any) => this.systemLanguagesSuccessCallback(response),
      () => this.systemLanguagesErrorCallback()
    );

    // Bingli feature
    if (this.bookingService.patientData === null || this.bookingService.patientData === undefined) {
      this.bookingService.patientDataSubject.subscribe({
        next: (patientData) => this.bookingService.setFormattedUserData(patientData)
      });
    } else {
      this.bookingService.setFormattedUserData(this.bookingService.patientData);
    }

    if (this.userData) {
      this.utilitiesService.decryptUserData(this.userData, response => this.decryptUserDataSuccess(response),
        error => this.decryptUserDataError(error));
    }

    // Get agenda
    let params = new HttpParams(); // Add params of select rules if we have it
    params = params.append('language', this.translateService.currentLang);

    // Load Feature for an agenda
    this.agendaService.getAgendaWithFeatures(this.calendarService.selectedAgenda.externalId, params)
      .then((result: any) => this.getAgendaFeaturesCallback(result))
      .catch((error: any) => console.error(error));
  }

  /**
   * Get agenda with features callback
   *
   * @param response
   * @private
   */
  private getAgendaFeaturesCallback(response: any) {
    if (response.features) {
      this.calendarService.availableFeatures = {...this.calendarService.availableFeatures, ...response.features};
    }

    this.checkFeatureSteps();
  }

  /**
   * After view init
   */
  ngAfterViewInit(): void {
    this.trackingService.eventRequestBooking(this.steppers[this.currentStep].name);
  }

  /**
   * Error callback for bookingService - getSystemLanguages
   */
  private systemLanguagesErrorCallback() {
    const alert = new Alert();
    alert.type = 'error';
    alert.text = '__title_error_occurred';
    this.alertService.setAlert(alert);
  }

  /**
   * Success callback for bookingService - getSystemLanguages
   *
   * @param result
   */
  private systemLanguagesSuccessCallback(result: any) {
    result.languages.forEach((language: any) => {
      if (this.translateService.currentLang === language.code) {
        this.systemLanguageId = language.id;
      }
    });
  }

  /**
   * Called when user change step inside the stepper
   *
   * @param e
   */
  changeStep(e: StepperSelectionEvent) {
    // Reinit alert if we go to the next step
    if (e.selectedIndex > e.previouslySelectedIndex) {
      this.alertService.setAlert(null);
    }

    this.trackingService.eventRequestBooking(this.steppers[this.currentStep].name);

    if (this.steppers[e.selectedIndex - 1]?.action) {
      this.steppers[e.selectedIndex - 1].action(e);
      return;
    }

    this.currentStep = e.selectedIndex;
  }

  /**
   * This function is meant to prevent the stepper from automatically going to the next
   * step when the loader is on. This won't work in the changeStep() output because it would
   * happen before the component naturally change step. The button click event happen slightly
   * after this, so it works this way. Ideally a callback from the stepper component would be welcome
   * but it doesn't exist.
   */
  waitBeforeNewStep() {
    if (this.steppers[this.stepper.selectedIndex - 1]?.waitBeforeNewStep) {
      this.stepper.selectedIndex = this.currentStep;
    }
  }

  /**
   * Prepare and format data for new appointment request
   *
   * @param e
   */
  private createAppointment(e: StepperSelectionEvent) {
    this.loaderOn = true;
    const slot = this.calendarService.selectedSlot,
      agenda = this.calendarService.selectedAgenda;
    let appointmentType = Appointment.TypeEnum.Normal;

    if (slot.status === Slot.StatusEnum.Timeframe) {
      appointmentType = Appointment.TypeEnum.TimeFrame;
    }

    const formData = this.generateFormData(),
      appointmentData = this.getAppointmentData(formData, slot, agenda, appointmentType);

    if (this.isReschedule) {
      this.bookingService.currentAppointment = this.calendarService.appointmentReschedule;
      this.appointmentService.rescheduleAppointment(this.calendarService.appointmentReschedule, appointmentData)
        .then((rescheduleResponse: any) => this.rescheduleAppointmentSuccessCallback(rescheduleResponse, e))
        .catch((error: HttpErrorResponse) => this.rescheduleAppointmentErrorCallback(error));
      return;
    }

    this.appointmentService.newAppointment(appointmentData, agenda.doctor.id)
      .then((appointment: Appointment) => this.createAppointmentSuccessCallback(appointment, e))
      .catch((error: HttpErrorResponse) => this.createAppointmentErrorCallback(error));
  }

  /**
   * Prepare and format data for confirm appointment request
   *
   * @param e
   */
  private confirmAppointment(e: StepperSelectionEvent) {
    const stepIndex = this.steppers.findIndex((step: any) => step.name === 'booking_verification'),
      confirmationData: any = Object.assign(this.steppers[stepIndex].inputs.formGroup.value, {
        bookingExternalSlotEid: this.calendarService.selectedSlot.bookingExternalSlotEid,
      });

    if (this.isReschedule) {
      confirmationData.oldAptEid = this.calendarService.appointmentReschedule.externalId;
    }

    this.appointmentService.confirmAppointment(this.bookingService.currentAppointment, confirmationData)
      .then((response: any) => this.confirmAppointmentSuccessCallback(response, e))
      .catch((error: HttpErrorResponse) => this.confirmAppointmentErrorCallback(error));
  }

  /**
   * Callback error action for confirmAppointment call
   *
   * @param response
   */
  private confirmAppointmentErrorCallback(response: HttpErrorResponse) {
    const alert = new Alert();
    alert.type = 'error';

    if (response.error.detail) {
      alert.text = response.error.detail;
    } else {
      switch (response.status) {
        case 410:
        case 409:
          alert.text = '__booking_slot_not_free_error_description';
          break;
        case 405:
          alert.text = '__booking_code_error';
          break;
        default:
          alert.text = '__booking_error_title';
      }
    }

    this.alertService.setAlert(alert);
    this.loaderOn = false;
  }

  /**
   * Callback action for confirmAppointment call
   *
   * @param response
   * @param event
   */
  private confirmAppointmentSuccessCallback(response: any, event: StepperSelectionEvent) {
    this.stepper.selectedIndex = event.selectedIndex;
    this.currentStep = event.selectedIndex;
    this.loaderOn = false;
    this.appointmentAlreadyAccepted = true;

    this.trackingService.eventRequestBooking(this.steppers[event.selectedIndex - 1].name);
  }

  /**
   * Callback success action for createAppointment call
   *
   * @param {Appointment} appointment
   * @param event
   */
  private createAppointmentSuccessCallback(appointment: Appointment, event: StepperSelectionEvent) {
    this.loaderService.endLoading();
    if (appointment?.id) {
      this.bookingService.currentAppointment = appointment;
      let currentIndex = event.selectedIndex;

      if (appointment.status > 0) {
        // Change step in booking process to go to the end
        currentIndex = currentIndex + 1;

        const stepIndex = this.steppers.findIndex((step: any) => step.name === 'booking_verification');
        this.steppers[stepIndex].isOptional = true;
        this.stepper.linear = false;

        this.appointmentAlreadyAccepted = (appointment.status === 2 || appointment.status === 4);
      }

      this.currentStep = currentIndex;
      this.stepper.selectedIndex = currentIndex;
      this.loaderOn = false;

      this.trackingService.eventRequestBooking(this.steppers[currentIndex - 1]);
    }
  }

  /**
   * Callback success action for rescheduleAppointment call
   *
   * @param rescheduleResponse
   * @param event
   */
  private rescheduleAppointmentSuccessCallback(rescheduleResponse: any, event: StepperSelectionEvent) {
    this.loaderService.endLoading();
    if (rescheduleResponse?.appointmentEid) {
      // Change appointment externalId to the new one
      this.bookingService.currentAppointment.externalId = rescheduleResponse.appointmentEid;
      this.bookingService.currentAppointment.status = 0;

      let currentIndex = event.selectedIndex;

      if (rescheduleResponse?.toBeConfirmed === false) {
        // Change step in booking process to go to the end
        currentIndex = currentIndex + 1;

        const stepIndex = this.steppers.findIndex((step: any) => step.name === 'booking_verification');
        this.steppers[stepIndex].isOptional = true;
        this.stepper.linear = false;

        this.appointmentAlreadyAccepted = true;
        this.bookingService.currentAppointment.status = 2;
      }

      this.currentStep = currentIndex;
      this.stepper.selectedIndex = currentIndex;
      this.loaderOn = false;

      this.trackingService.eventRequestBooking(this.steppers[currentIndex - 1]);
    }
  }

  /**
   * Callback error action for createAppointment call
   *
   * @param response
   */
  private createAppointmentErrorCallback(response: HttpErrorResponse) {
    this.loaderService.endLoading();
    const alert = new Alert();
    let displayAlert = true;
    alert.type = 'error';

    switch (response.status) {
      case 400:
        alert.text = '__error_on_birthday';
        break;
      case 405:
        alert.text = '__max_appointment_for_doctor';
        break;
      case 403:
        alert.text = '__already_waiting_appointment_speciality';
        break;
      case 406:
        alert.text = '__matricule_error_invalid';
        break;
      case 430:
        displayAlert = false;
        break;
      default:
        alert.text = '__booking_error_title';
    }

    if (!displayAlert) {
      this.loaderOn = false;
      return;
    }

    if (response.error.detail) {
      alert.text = response.error.detail;
    }

    this.alertService.setAlert(alert);
    this.loaderOn = false;
  }

  /**
   * Callback error action for rescheduleAppointment call
   */
  private rescheduleAppointmentErrorCallback(error: any) {
    this.loaderService.endLoading();
    const alert = new Alert();
    alert.type = 'error';
    alert.text = '__booking_error_title';

    this.alertService.setAlert(alert);
    this.loaderOn = false;
  }

  /**
   * Success method for decryptUserData call
   *
   * @param response
   * @private
   */
  private decryptUserDataSuccess(response: any) {
    if (response.result) {
      const result = response.result;
      this.bookingService.setFormattedUserData({
        patientGender: result.gender,
        patientFirstName: result.firstName,
        patientLastName: result.lastName,
        patientBirthday: DateTime.fromFormat(result.birthday, 'dd/LL/yyyy').toJSDate(),
        userEmail: result.email,
        userMobile: result.mobile,
        matricule: result.matricule
      });
    }
  }

  /**
   * Error method for decryptUserData call
   *
   * @param error
   * @private
   */
  private decryptUserDataError(error) {
    const alert = new Alert();
    alert.type = 'error';
    alert.text = '__error_decrypt_user_data';
    this.alertService.setAlert(alert);
  }

  /**
   * Extract appointment data from forms
   *
   * @param formData
   * @param slot
   * @param agenda
   * @param appointmentType
   */
  private getAppointmentData(formData: any, slot: Slot, agenda: Agenda,
                             appointmentType: Appointment.TypeEnum.Normal | Appointment.TypeEnum.TimeFrame) {

    // Reschedule appointment
    if (this.isReschedule) {
      return {
        startDate: slot.startTimestamp,
        endDate: slot.endTimestamp,
        bookingExternalSlotEid: slot.bookingExternalSlotEid,
      };
    }

    const data = {
      ...formData, ...{
        // The only properties specified below are those not treated in the form or
        // those that needs to be modified before being sent
        returningPatient: (formData.returningPatient === 'true'), // String to Bool conversion
        slot_id: slot.id,
        bookingExternalSlotEid: slot.bookingExternalSlotEid,
        start: slot.start.toFormat('yyyy-LL-dd TT'),
        end: slot.end.toFormat('yyyy-LL-dd TT'),
        practice: agenda.practice.id,
        doctorReasonOfVisit: this.selectedReason.id,
        userFirstName: !formData.userFirstName ? formData.patientFirstName : formData.userFirstName,
        userLastName: !formData.userLastName ? formData.patientLastName : formData.userLastName,
        patientBirthday: formData.patientBirthday ?
          DateTime.fromJSDate(formData.patientBirthday).toFormat('yyyy-LL-dd TT') :
          null,
        language: this.systemLanguageId,
        insurance: this.getInsuranceParam(),
        optionFields: {
          booking_rule_selection: this.getSelectedBookingRules(),
          custom_booking_fields: {
            matricule: formData.matricule || '',
            matriculeIso: formData.matriculeIso || '',
          },
          prescription_number: formData.prescriptionNumber || '',
          date_term: formData.dateTerm || '',
        },
        type: appointmentType,
      }
    };

    // Data send from verified user so we don't send confirmation sms
    if (this.userData) {
      data.dontSendConfirmationSms = true;
    }

    // Add fileAttachments if exists
    if (formData.fileAttachments) {
      data.fileAttachments = formData.fileAttachments;
    }

    // Remove from response to not create conflicts
    delete data.dateTerm;
    delete data.prescriptionNumber;

    return data;
  }

  /**
   * Get object with selected bookingRules values
   */
  private getSelectedBookingRules() {
    const selectedBookingRules = {};
    (this.calendarService.bookingParams['bookingRules'] || []).forEach((bookingRule: any) => {
      selectedBookingRules[bookingRule.name] = bookingRule.selectedValue;
    });

    return selectedBookingRules;
  }

  /**
   * Get booking rules
   */
  private getInsuranceParam() {
    let insurance = null;
    if (this.calendarService.bookingParams['bookingRules']) {
      this.calendarService.bookingParams['bookingRules'].forEach((bookingRule: any) => {
        switch (bookingRule.name) {
          case 'insuranceType':
            insurance = this.getInsuranceValueFromInsuranceType(bookingRule.selectedValue);
            break;
          case 'insurance':
            insurance = ((bookingRule.selectedValue === 'public') || (bookingRule.selectedValue === 'GKV')) ? 2 : 4;
            break;
          default:
            break;
        }
      });
    }

    return insurance;
  }

  /**
   * Get appointment insurance value from insuranceTypeValue
   *
   * @param insuranceType
   */
  protected getInsuranceValueFromInsuranceType(insuranceType: any) {
    switch (insuranceType) {
      case 'PUBLIC':
        return 2;
      case 'PRIVATE':
        return 4;
      case 'SELFPAYING':
        return 8;
      case 'TRADEASSOCIATION':
        return 32;
      default:
        return null;
    }
  }

  /**
   * Generate forms data to send to the API
   *
   * @private
   */
  private generateFormData() {
    let formData: any = {};

    this.steppers.forEach(stepper => {
      if (stepper.hasAppointmentData) {
        formData = {...formData, ...stepper.inputs.formGroup.getRawValue()};
      }

      if (stepper.extraData) {
        formData = {...formData, ...stepper.extraData};
      }
    });

    // Define bingli survey ID in data if exists
    if (this.bookingService.surveyId) {
      this.surveyId = this.bookingService.surveyId;
      formData.mybingliSurveyData = this.surveyId;
    }

    const customQuestionsData = [];

    Object.keys(formData).forEach((key) => {
      // Check if data is an answer to a custom question and if so store it in an object
      const splitQuestionKey = key.split('question_');
      if (splitQuestionKey.length === 2) {
        customQuestionsData.push({
          question_eid: splitQuestionKey[1],
          answer: {
            value: (formData[key] instanceof Date) ? formData[key].toLocaleDateString() : formData[key],
          }
        });
        delete formData[key];
      }

      // Format any input name that has a point in it, and create an object instead.
      const splitKey = key.split('.');
      if (splitKey.length === 2) {
        const parentKey = splitKey[0];
        const childKey = splitKey[1];
        formData[parentKey] = formData[parentKey] ?
          Object.assign(formData[parentKey], {[childKey]: formData[key]}) :
          {[childKey]: formData[key]};
        delete formData[key];
      }
    });

    if (customQuestionsData.length) {
      formData.customQuestionsAnswers = customQuestionsData;
    }

    return formData;
  }

  /**
   * Send files to the server
   *
   * @param e
   * @private
   */
  private sendFiles(e: StepperSelectionEvent) {
    this.loaderService.newLoading();
    this.loaderOn = true;
    const formData = this.steppers[e.selectedIndex - 1].inputs.formGroup.value,
      doctorEid: string = this.calendarService.selectedAgenda.doctor.externalId;

    return this.fileUploadService.uploadAppointmentFileAttachments(formData, doctorEid)
      .then((uploadedFileAttachments: any) => {
        this.steppers[e.selectedIndex - 1].extraData = {
          fileAttachments: uploadedFileAttachments ?? []
        };
        this.createAppointment(e);
      })
      .catch((error: any) => {
        this.loaderService.endLoading();
        const alert = new Alert();
        alert.type = 'error';
        alert.text = '__booking_error_title';
        this.alertService.setAlert(alert);
        this.loaderOn = false;
        console.error(error);
      });
  }

  /**
   * Check if the feature steps should be added to the stepper
   *
   * @private
   */
  private checkFeatureSteps() {
    if (this.calendarService.availableFeatures?.patientFileUpload && this.selectedReason?.fileAttachmentSettings?.length) {
      // Insert file-upload step after booking_patient_info_step2 step
      const stepIndex = this.steppers.findIndex((step: any) => step.name === 'booking_patient_info_step2');
      this.steppers.splice(stepIndex + 1, 0, {
        name: 'booking_file_upload',
        isOptional: false,
        component: BookingFileUploadStepComponent,
        inputs: {
          formGroup: this.formBuilder.group({})
        },
        action: (e: StepperSelectionEvent) => {
          if (this.loaderOn) {
            return;
          }
          this.sendFiles(e);
        },
        extraData: {},
        label: '__booking_file_upload_step',
        hasAppointmentData: false,
        waitBeforeNewStep: true
      });

      // Change the action of the previous step to go to the file-upload step
      this.steppers[stepIndex].action = (e: StepperSelectionEvent) => {
        if (!this.loaderOn) {
          this.currentStep = e.selectedIndex;
          this.trackingService.eventRequestBooking('booking_patient_info_step2');
        }
      };
    }
  }
}
